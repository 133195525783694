import React from 'react';
import ActionButton from '../buttons/ActionButton';
import BannerTopGif from '../../assets/images/mobile-new.gif'


const Banner = () => {

  return (
    <>
        <section className='relative lg:h-screen h-auto banner-bg overflow-hidden flex items-start z-10 w-full gradient-border-custom gradient-border-red bg-black' id='home-banner'>
            <div className='lg:max-w-screen-xl md:max-w-screen-md max-w-screen-sm px-5 xl:px-0 w-full flex flex-wrap items-center justify-between mx-auto'>
                <div className="w-full lg:pt-36 pt-28 text-center">
                    <h1 className='xl:text-5xl md:text-3xl text-2xl font-bold text-white' style={{lineHeight:"1.25"}}>Accelerate your Fleet 
                        <br/> with <span className='text-dark-red shadow-light relative'>Piston.</span>
                    </h1>
                    <p className='lg:text-2xl text-base font-medium mt-3 text-opacity-75'>Revolutionize Your Fuel Management Today!</p>
                    <div className="flex lg:gap-8 gap-4 lg:mt-6 mt-10 justify-center">
                        <a href="#contact-form" className='text-white bg-dark-red hover:bg-[#9a1c1f] rounded-lg lg:text-base text-sm font-bold px-8 lg:py-3 py-2 text-center flex items-center'>
                            Get Started 
                        </a>
                        <ActionButton 
                        text="Request a Demo"
                        onClick={null}
                        className={"text-white bg-black hover:bg-[#333] border-gray-custom rounded-md"}
                        />
                    </div>
                </div>
                <div className="w-full lg:mt-5 mt-10">
                    <img src={BannerTopGif} alt='Piston Hero' />
                </div>
            </div>

            
        </section> 
    </>
  )
}

export default Banner