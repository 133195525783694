import React from 'react'
import { useLocation } from 'react-router-dom';
import { GAS_STATION_URL } from '../../libs/constants';

const VideoSec = ({src, title, index, paddingTop}) => {

    const titleArr = title?.split(" ");
    const pathName = useLocation();

  return (
    <>
    <div className={`w-full bg-black px-5 ${paddingTop}`}>
        <h2 className='lg:text-4xl md:text-2xl text-xl font-medium text-center'>
            {titleArr?.map((word, idx) => (
                <React.Fragment key={idx}>
                {idx === index ? (
                <span className="text-dark-red shadow-red relative">
                    {word}
                </span>
                ) : (
                word
                )}
                {idx === index && <br />} {/* Insert <br /> after the highlighted word */}
                {idx < titleArr.length - 1 && ' '}
            </React.Fragment>
            ))}
        </h2>
    </div>

    <section className={`relative w-full lg:pt-24 pt-20 pb-16 py-5 video-sec overflow-hidden bg-black gradient-border-custom gradient-border-light`}>
        
        <div className="w-full background-blur-custom rounded-lg border-t border-gray-600 border-opacity-50">
            <div className='lg:max-w-screen-lg md:max-w-screen-md max-w-screen-sm w-full flex items-center justify-center mx-auto gap-3 lg:py-20 py-10 px-5 lg:px-0'>
                <div className="flex justify-center w-full border border-gray-custom rounded-lg">
                    <iframe className='rounded-lg' width="100%" src={src} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;compute-pressure" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                </div>
            </div>
        </div>
    </section>
</>
  )
}

export default VideoSec