import React, { useEffect, useRef, useState } from "react";
import ActionButton from "../buttons/ActionButton";
import InputMask from "react-input-mask";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";

const TermsAndConditions = () => {
  return (
    <>
      <div
        className="contact-sec relative w-full gradient-border-custom gradient-border-light pb-16 bg-black"
        id="contact-form"
      >
        <section className="relative w-full pt-12">
          <div className="lg:max-w-screen-xl md:max-w-screen-md max-w-screen-sm px-5 xl:px-0 w-full flex flex-wrap items-center justify-center mx-auto">
            <div className="flex justify-center flex-col w-full gap-5">
              <h2 className="lg:text-4xl md:text-2xl text-xl font-medium text-center">
                Terms of Use
              </h2>
            </div>
          </div>
        </section>
        <section className="relative max-h-full lg:py-15 pb-12 pt-8 overflow-hidden flex items-center z-10 w-full">
          <div className="lg:max-w-screen-lg md:max-w-screen-md max-w-screen-sm px-5 xl:px-0 w-full flex flex-wrap items-center justify-between mx-auto">
            <div className="w-full mx-auto bg-black py-10 lg:px-16 px-5">
              <p className="text-white text-opacity-50 mb-2">
                Last Updated: May 6th, 2024
              </p>
              <h5 className="text-base text-white text-opacity-70 leading-8 mb-4 font-bold">
                THESE TERMS OF USE (“TOU”) FORM A LEGAL AGREEMENT BETWEEN YOU,
                THE END USER (“You”) AND Piston Technologies, Inc (“Piston”)
                THAT APPLIES EACH TIME YOU USE OR ACCESS THE LICENSED PLATFORM
                AND/OR MOBILE APPLICATION MADE AVAILABLE AND THEIR ASSOCIATED
                CONTENT, FEATURES, TOOLS, AND MATERIALS (collectively, the
                “Platform”). YOU SHOULD THEREFORE READ CAREFULLY THE FOLLOWING
                TERMS AND CONDITIONS AS THEY GOVERN YOUR USE OF THE PLATFORM AND
                ITS FUNCTIONALITY.
              </h5>
              <h5 className="text-base text-white text-opacity-70 leading-8 mb-4 font-bold">
                IN PARTICULAR, THIS TOU CONTAINS AN ARBITRATION PROVISION THAT
                WAIVES YOUR RIGHT TO A COURT HEARING AND/OR A JURY TRIAL AND A
                CLASS ACTION WAIVER THAT WAIVES YOUR RIGHT TO PARTICIPATE IN ANY
                CLASS ACTION LAWSUIT TO ENFORCE THIS CONTRACT.
              </h5>
              <h5 className="text-base text-white text-opacity-70 leading-8 mb-8 font-bold">
                IF YOU DO NOT AGREE WITH THIS TOU, DO NOT USE THE PLATFORM
                BECAUSE YOU ARE NOT GRANTED PERMISSION TO ACCESS OR OTHERWISE
                USE THE PLATFORM AND ARE INSTRUCTED TO EXIT, UNINSTALL, AND/OR
                CEASE ALL USE OF THE PLATFORM IMMEDIATELY. License Grant and
                Restrictions
              </h5>
              <p className="text-white text-opacity-50 mb-3 leading-7">
                This TOU provides to You a personal, revocable, limited,
                non-exclusive, non-sublicensable, and nontransferable license to
                use and access the Platform solely for Your personal, internal
                business and informational use as specifically described herein,
                conditioned on Your continued compliance with this TOU.
              </p>
              <p className="text-white text-opacity-50 leading-7 mb-8">
                When using the Platform in accordance with the foregoing
                license, You shall not directly or indirectly: (a) use the
                Platform to create any service, software or documentation that
                performs substantially the same functionality as the Platform;
                (b) disassemble, decompile, reverse-engineer or use any other
                means to attempt to discover any source code, algorithms, or
                trade secrets underlying the Platform or any of its components;
                (c) encumber, sublicense, transfer, distribute, rent, lease,
                time-share or use the Platform in any service bureau arrangement
                or similar commercial purposes; (d) adapt, combine, create
                derivative works of or otherwise modify the Platform; (e)
                disable, circumvent, or otherwise avoid or undermine any
                security device, mechanism, protocol, or procedure implemented
                in the Platform; (f) use or access the Platform for any
                unlawful, fraudulent, deceptive, malicious, or otherwise harmful
                or injurious purpose; (g) remove, obscure, deface, or alter any
                proprietary rights notices on any element of the Platform or
                accompanying documentation; (h) use the Platform in any manner
                which could damage, disable, overburden, or impair the Platform
                or interfere with any third party’s authorized use of the
                Platform; (i) engage in any “data mining,” “deep-link,”
                “page-scrape,” or use “bots,” “spiders,” or similar data
                gathering and extraction tools or methods in connection with the
                Platform; or (j) probe, scan, or test the vulnerability of the
                Platform or any systems or network infrastructure connected with
                the Platform, including without limitation by stress testing or
                penetration testing.
              </p>

              <ul className="list-disc ps-4">
                <li>
                  <p className="mb-4 text-white text-opacity-70">
                    User Obligations
                  </p>
                  <p className="text-white text-opacity-50 leading-7 mb-3">
                    You represent that You are at least eighteen (18) years of
                    age or the legal age of majority in Your jurisdiction
                    (whichever is greater) and will, at all times, provide true,
                    accurate, current, and complete information (for which You
                    have all necessary rights, permission(s), or authority to
                    provide) when submitting information through the Platform.
                    In addition, You are responsible for compliance with all
                    applicable laws, rules, and regulations with respect to Your
                    use of the Platform (including use of any permitted copies
                    of the Platform materials and information).{" "}
                  </p>
                  <p className="text-white text-opacity-50 leading-7 mb-5">
                    The Platform is not intended for use or access by any
                    individual under the age of thirteen (13) years, and Piston
                    does not knowingly, willingly, or intentionally collect
                    personal information from such individuals in any medium for
                    any purpose.
                  </p>
                </li>
                <li>
                  <p className="mb-4 text-white text-opacity-70">
                    Your Account
                  </p>
                  <p className="text-white text-opacity-50 leading-7 mb-5">
                    You may be required to register an account to use the
                    Platform or certain features of the Platform. Each
                    registration is for a single user only, unless otherwise
                    expressly agreed by Piston. Registration for the Platform
                    may also require access credentials, such as a username and
                    a password, or adherence to other access requirements as
                    designated by Piston in its sole discretion from time to
                    time. You hereby agree to treat Your access credentials as
                    confidential and not to disclose such information to any
                    third party without the express prior written consent of
                    Piston, which may be granted or withheld in its sole
                    discretion. You shall immediately notify Piston if You
                    suspect or become aware of any loss, theft, or unauthorized
                    use of Your login credentials. Piston will not be liable for
                    any loss or damage arising from Your failure (whether
                    intentional or unintentional) to comply with these
                    obligations. By submitting the requested information to the
                    registration form or similar process on the Platform, You
                    represent and warrant that the information You submit for
                    registration is complete, true, accurate, and current in all
                    respects. You must maintain and promptly update Your
                    submitted account information to ensure that such
                    information is complete, true, accurate, and current. Piston
                    reserves the right to suspend, terminate, or otherwise
                    discontinue Your account and/or pending registration if
                    Piston has reasonable grounds to suspect that any
                    information You have submitted is untrue, inaccurate, not
                    current, or incomplete, or that Your registration, account,
                    or use of the Platform is in violation of applicable law or
                    this TOU.
                  </p>
                </li>
                <li>
                  <p className="mb-4 text-white text-opacity-70">
                    Electronic Communications
                  </p>
                  <p className="text-white text-opacity-50 leading-7 mb-5">
                    Piston may send emails or other electronic messages to You
                    concerning Your use of the Platform. You consent to receive
                    such electronic communications and You agree that all such
                    electronic communications constitute valid legal notices
                    satisfying any requirement that such notices be in writing.
                  </p>
                </li>
                <li>
                  <p className="mb-4 text-white text-opacity-70">
                    Links to Third-Party Sites and Services
                  </p>
                  <p className="text-white text-opacity-50 leading-7 mb-5">
                    Certain services, features, or components made available via
                    the Platform are delivered by third-party providers. By
                    using any product, service, or functionality originating
                    from the Platform, You hereby acknowledge and consent that
                    Piston may share such information and data with the
                    applicable third-party provider as may be required to enable
                    and facilitate the requested third-party product, service,
                    or functionality via the Platform.
                  </p>

                  <h5 className="text-white text-opacity-50 leading-7 mb-5">
                    PISTON EXPRESSLY DISCLAIMS RESPONSIBILITY AND LIABILITY FOR
                    ANY THIRD-PARTY MATERIALS, PROGRAMS, APPLICATIONS, TOOLS,
                    PRODUCTS, AND SERVICES SET FORTH, DESCRIBED ON, OR ACCESSED
                    THROUGH THE PLATFORM, AND YOU AGREE THAT PISTON SHALL NOT BE
                    RESPONSIBLE FOR ANY LOSS OR DAMAGE INCURRED AS A RESULT OF
                    ANY DEALINGS BETWEEN YOU AND A THIRD PARTY, WHETHER OR NOT
                    FACILITATED BY OR MADE IN CONNECTION WITH THE PLATFORM{" "}
                  </h5>
                </li>
                <li>
                  <p className="mb-4 text-white text-opacity-70">
                    Third-Party Terms and Conditions{" "}
                  </p>
                  <p className="text-white text-opacity-50 leading-7 mb-5">
                    Additional notices, terms, and conditions may apply to
                    products, services, receipt of or access to certain
                    materials, participation in a particular program, and/or
                    specific portions or features of the Platform, including
                    without limitation the terms of third-party applications
                    incorporating or utilizing the Platform and/or Your accounts
                    with third-party providers of such applications. You hereby
                    agree that: (a) this TOU operates in addition to any terms
                    of use imposed or required by any such third-party provider;
                    and (b) the terms of this TOU supplement and do not alter or
                    amend any such third-party terms of use.
                  </p>
                </li>
                <li>
                  <p className="mb-4 text-white text-opacity-70">
                    Data Privacy
                  </p>
                  <p className="text-white text-opacity-50 leading-7 mb-5">
                    You understand, acknowledge, and agree that the operation of
                    certain features of the Platform may require or involve the
                    provision, use, and dissemination of various items of
                    personally identifiable information, including without
                    limitation personal contact information. Please refer to the
                    Piston Privacy Policy available at
                    www.usepiston.com/privacypolicy (as may be updated by Piston
                    from time to time), for a summary of Piston’s policies and
                    practices regarding personally identifiable information.
                  </p>
                </li>
                <li>
                  <p className="mb-4 text-white text-opacity-70">
                    Proprietary Rights{" "}
                  </p>
                  <p className="text-white text-opacity-50 leading-7 mb-3">
                    All materials and information included as part of the
                    Platform, including without limitation all content, text,
                    graphics, logos, and images made available on the Platform,
                    as well as the compilation thereof, and any software or
                    other proprietary materials used on or integrated with the
                    Platform, are the property of Piston or its applicable
                    third-party licensors, and are protected by copyright and
                    other laws governing intellectual property and proprietary
                    rights. Piston reserves all rights in the Platform not
                    expressly granted herein.{" "}
                  </p>
                  <p className="text-white text-opacity-50 leading-7 mb-5">
                    You agree that You do not acquire any ownership rights in
                    any part of the Platform under this TOU or through Your use
                    of the Platform. Piston does not grant You any rights or
                    licenses, express or implied, to any intellectual property
                    hereunder except as specifically authorized by this TOU.
                  </p>
                </li>
                <li>
                  <p className="mb-4 text-white text-opacity-70">
                    Mobile Usage
                  </p>
                  <p className="text-white text-opacity-50 leading-7 mb-5">
                    The Platform offers various tools or display functionality
                    that may be accessible via Your mobile phone or other mobile
                    computing device. Please note that Your mobile carrier’s
                    normal messaging, data, and other rates and fees will apply
                    to Your use of the Platform. In addition, downloading,
                    installing, or using the Platform on Your mobile device may
                    be prohibited or restricted by Your mobile carrier, and not
                    all functionality on the Platform will work with all
                    carriers or devices or in all locations. Therefore, You are
                    solely responsible for: (i) checking with Your mobile
                    carrier to determine if the Platform is available for Your
                    mobile devices; (ii) the restrictions, if any, may be
                    applicable to Your use of the Platform; and (iii)
                    determining and keeping track of the network and data fees
                    or similar charges associated with such use.
                  </p>
                  <p className="font-bold mb-4 text-white text-opacity-60">
                    Additional Terms and Conditions for Apple Users
                  </p>
                  <p className="text-white text-opacity-50 leading-7 mb-5">
                    <span className="underline">NOTE – </span> The terms and
                    conditions of this paragraph apply to You only if You
                    downloaded Piston’s mobile application (the “App”) through
                    Apple Inc.’s App Store. You acknowledge that this TOU is
                    between You and Piston, and that Apple Inc. (“Apple”) bears
                    no responsibility for the App and its content. The license
                    grant under this TOU with respect to the App is a
                    non-transferable license to use the App on any Apple-branded
                    products that You own or control as permitted by this TOU
                    and the Usage Rules set forth in the Apple Media Services
                    Terms and Conditions, except that the App may be accessed
                    and used by other accounts associated with You via “Family
                    Sharing” (as defined in the Apple Media Services Terms and
                    Conditions) or volume purchasing. You acknowledge that Apple
                    has no obligation whatsoever to furnish any maintenance and
                    support services with respect to the App. In the event of
                    any failure of Piston’s mobile app to conform to any
                    applicable warranty, You may notify Apple, and Apple will
                    refund the purchase price (if any) of the App to You;
                    provided that, to the maximum extent permitted by applicable
                    law, Apple will have no other warranty obligation whatsoever
                    with respect to the App. Apple is not responsible for
                    addressing any claims by You or a third party relating to
                    the App or Your use of the App, including without
                    limitation: (i) product liability claims; (ii) any claim
                    that the App fails to conform to any applicable legal or
                    regulatory requirement; and (iii) claims arising under
                    consumer protection, privacy, or similar legislation. In the
                    event of any third-party claim that the App or Your
                    possession and use thereof infringes a third party’s
                    intellectual property rights, Apple will not be responsible
                    for any investigation, defense, settlement, or discharge
                    thereof. Apple and its subsidiaries are third-party
                    beneficiaries of this TOU, and upon Your acceptance of this
                    TOU, Apple will have the right (and will be deemed to have
                    accepted the right) to enforce this TOU against You as a
                    third-party beneficiary thereof.{" "}
                  </p>
                </li>
                <li>
                  <p className="mb-4 text-white text-opacity-70">Feedback</p>
                  <p className="text-white text-opacity-50 leading-7 mb-5">
                    “Feedback” means any feedback, recommendations, enhancement
                    requests, suggestions, testimonials, endorsements, or ideas
                    for improvement communicated to Piston by You regarding the
                    Platform or Piston’s products and services. From time to
                    time, You may voluntarily provide Feedback to Piston,
                    whether or not in response to specific requests or
                    solicitation by Piston. In such event, You grant to Piston a
                    perpetual, irrevocable, fully transferable, freely
                    sublicensable, royalty-free, worldwide license to make, have
                    made, use, import, offer for sale, sell, reproduce,
                    distribute, modify, adapt, prepare derivative works of,
                    display, perform, and otherwise exploit such Feedback for
                    any purpose in Piston’s discretion without restriction. The
                    foregoing license to Feedback shall survive the termination
                    or expiration of this TOU for any reason.
                  </p>
                </li>
                <li>
                  <p className="mb-4 text-white text-opacity-70">
                    Indemnification
                  </p>
                  <p className="text-white text-opacity-50 leading-7 mb-5">
                    You agree to indemnify, defend and hold harmless Piston and
                    the parties with which Piston contracts to offer the
                    Platform services, and their respective officers, directors,
                    employees, agents, successors, and assigns from and against
                    any losses, costs, liabilities, damages, and expenses
                    (including reasonable attorney's fees) relating to or
                    arising out of Your violation of (a) this TOU, (b) any
                    personal or proprietary rights of a third party, or (c) any
                    applicable laws, rules, or regulations. Piston reserves the
                    right, at its own cost, to assume the exclusive defense and
                    control of any matter otherwise subject to indemnification
                    by You, in which event You will fully cooperate with Piston
                    in asserting any available defenses.
                  </p>
                </li>
                <li>
                  <p className="mb-4 text-white text-opacity-70">
                    Disclaimers and Excluded Liability
                  </p>
                  <p className="text-white text-opacity-50 leading-7 mb-5 font-bold">
                    PISTON DOES NOT REPRESENT OR WARRANT THAT THE PLATFORM WILL
                    OPERATE ERROR-FREE OR ON AN UNINTERRUPTED BASIS. THE
                    PLATFORM IS PROVIDED “AS IS” AND “AS AVAILABLE,” AND TO THE
                    MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, PISTON HEREBY
                    DISCLAIMS ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED,
                    INCLUDING WITHOUT LIMITATION ANY IMPLIED WARRANTIES OF
                    TITLE, NON-INFRINGEMENT, MERCHANTABILITY, OR FITNESS FOR A
                    PARTICULAR PURPOSE. WITHOUT LIMITING THE FOREGOING, YOU
                    ACKNOWLEDGE THAT PISTON MAKES NO REPRESENTATION, WARRANTY,
                    OR GUARANTEE WHATSOEVER REGARDING THE QUALITY, ACCURACY,
                    EFFECTIVENESS, LEGALITY, RELIABILITY, OR TIMELINESS OF ANY
                    SERVICES, CONTENT, OR INFORMATION MADE AVAILABLE IN
                    CONNECTION THEREWITH OR ANY RESULTS OBTAINED THEREFROM. YOU
                    AGREE THAT YOUR USE OF AND ACCESS TO THE PLATFORM ARE
                    ENTIRELY AT YOUR OWN RISK, AND THAT YOU ARE SOLELY LIABLE
                    AND RESPONSIBLE FOR THE RESULTS OF SUCH USE AND ACCESS, SUCH
                    THAT Piston SHALL HAVE NO LIABILITY OR RESPONSIBILITY
                    THEREFOR UNDER ANY LEGAL THEORY OR CAUSE OF ACTION NOW KNOWN
                    OR HEREAFTER ESTABLISHED.
                  </p>
                  <p className="text-white text-opacity-50 leading-7 mb-5 font-bold">
                    IN NO EVENT SHALL PISTON BE LIABLE HEREUNDER, REGARDLESS OF
                    THE FORM OF CLAIM OR ACTION (WHETHER IN CONTRACT,
                    NEGLIGENCE, STATUTORY LIABILITY OR OTHERWISE), FOR ANY LOSS
                    OR INACCURACY OF DATA, LOSS OR INTERRUPTION OF USE, OR COST
                    OF PROCURING SUBSTITUTE TECHNOLOGY, GOODS, OR SERVICES, OR
                    ANY INDIRECT, PUNITIVE, INCIDENTAL, RELIANCE, SPECIAL,
                    EXEMPLARY, OR CONSEQUENTIAL DAMAGES, INCLUDING WITHOUT
                    LIMITATION DAMAGES FOR LOSS OF BUSINESS, REVENUES, PROFITS
                    AND/OR GOODWILL, EVEN IF SUCH DAMAGES WERE REASONABLY
                    FORSEEABLE OR IF PISTON WAS ADVISED OF THE POSSIBILITY OF
                    SUCH DAMAGES. THESE LIMITATIONS SHALL APPLY NOTWITHSTANDING
                    THE INSUFFICIENCY OR FAILURE OF ANY REMEDY PROVIDED HEREIN.
                  </p>
                  <p className="text-white text-opacity-50 leading-7 mb-5 font-bold">
                    CERTAIN JURISDICTIONS AND LAWS MAY NOT PERMIT SOME OR ALL OF
                    THE DISCLAIMERS OF LIABILITY SET FORTH IN THIS SECTION. IN
                    THE EVENT THAT SUCH A JURISDICTION OR LAW APPLIES TO THE
                    SUBJECT MATTER OF THIS TOU, THE FOREGOING DISCLAIMERS WILL
                    APPLY TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW.
                  </p>
                </li>
                <li>
                  <p className="mb-4 text-white text-opacity-70">
                    No Professional Advice
                  </p>
                  <p className="text-white text-opacity-50 leading-7 mb-5">
                    The content and information contained in or made available
                    through the Platform (including but not limited to any
                    reports, graphics, statistics, methodologies, illustrations,
                    or practices disclosed therein) cannot replace or substitute
                    for the services of qualified professionals in any field,
                    and nothing contained on the Platform should be treated or
                    construed as professional financial, tax, medical,
                    psychological, or legal matters. You acknowledge that You
                    are solely responsible and accountable for Your decisions,
                    actions, and results regarding real estate and finances,
                    tax, and personal health, and agree that Piston shall not be
                    held liable for any decisions made by You in reliance or
                    with reference to any content or information that You access
                    on the Platform.{" "}
                  </p>
                </li>
                <li>
                  <p className="mb-4 text-white text-opacity-70">
                    Term, Termination, and Suspension
                  </p>
                  <p className="text-white text-opacity-50 leading-7 mb-3">
                    This TOU takes effect (or re-takes effect) at the moment You
                    first access, use, install, and/or log into the Platform.
                    Piston reserves the right at any time and on any grounds,
                    including without limitation any reasonable belief of
                    fraudulent or unlawful activity, to deny or suspend Your
                    access to the Platform, or to any portion thereof, in order
                    to protect its name and goodwill, its business, its
                    ownership and other rights in the Platform or any content or
                    information therein, the security and stability of the
                    Platform, and/or the rights of others. This TOU terminates
                    automatically if You fail to comply with any provision
                    hereof, subject to the survival rights of certain provisions
                    identified below. You may also terminate this TOU at any
                    time by ceasing to use the Platform, but each re-access or
                    renewed use of the Platform will reapply the TOU to You.
                    Upon termination or expiration of the TOU for any reason,
                    all licenses granted by Piston hereunder shall immediately
                    terminate, and You must immediately cease all use of the
                    Platform. The provisions of this TOU concerning Piston’s
                    proprietary rights, Feedback, disclaimers of warranty,
                    limitations of liability, waiver and severability, entire
                    agreement, indemnification rights, arbitration, class action
                    waiver, injunctive relief, and governing law will survive
                    the termination of this TOU for any reason.{" "}
                  </p>

                  <p className="text-white text-opacity-50 leading-7 mb-3">
                    Without limiting the foregoing, Piston reserves the right,
                    in its sole discretion, to terminate or suspend Your access
                    to the Platform or any feature or portion thereof at any
                    time, without notice to You, if Piston reasonably determines
                    or suspects that You are in violation of this TOU.
                  </p>
                </li>
                <li>
                  <p className="mb-4 text-white text-opacity-70">Arbitration</p>
                  <p className="text-white text-opacity-50 leading-7 mb-5">
                    In the event the parties hereto are not able to resolve any
                    dispute between them arising out of or concerning this TOU,
                    or any provisions hereof, whether arising in contract, tort,
                    or otherwise at law or in equity for damages or any other
                    relief, then such dispute shall be resolved exclusively
                    through final and binding arbitration pursuant to the
                    Federal Arbitration Act, conducted by a single neutral
                    arbitrator and administered under the Commercial Arbitration
                    Rules of the American Arbitration Association. The exclusive
                    site of such arbitration shall be in New York, New York. The
                    arbitrator's award shall be final, and judgment may be
                    entered upon it in any court having jurisdiction. The
                    prevailing party shall be entitled to recover its costs and
                    reasonable attorney's fees. The entire dispute, including
                    the scope and enforceability of this arbitration provision,
                    shall be determined by the arbitrator. This arbitration
                    provision shall survive the termination of this TOU for any
                    reason. Notwithstanding the foregoing, Piston may bring a
                    claim for injunctive relief against Your violation of this
                    TOU in any court of competent jurisdiction.
                  </p>
                </li>
                <li>
                  <p className="mb-4 text-white text-opacity-70">
                    Class Action Waiver
                  </p>
                  <p className="text-white text-opacity-50 leading-7 mb-5">
                    BY USING THE PLATFORM AND AGREEING TO THESE TERMS, YOU
                    HEREBY WILLINGLY, EXPRESSLY, AND KNOWINGLY WAIVE ALL RIGHT
                    TO BRING OR PARTICIPATE IN ANY CLASS-ACTION LAWSUIT,
                    CLASS-WIDE ARBITRATION, OR PRIVATE ATTORNEY-GENERAL ACTION
                    BROUGHT UNDER OR IN CONNECTION WITH THIS TOU OR YOUR USE OF
                    THE PLATFORM. YOU MAY NOT BRING ANY CLAIM, SUIT, OR OTHER
                    PROCEEDING TO ENFORCE THIS TOU AS THE MEMBER OF ANY CLASS OR
                    AS PART OF ANY SIMILAR COLLECTIVE OR CONSOLIDATED ACTION.{" "}
                  </p>
                </li>
                <li>
                  <p className="mb-4 text-white text-opacity-70">
                    Governing Law
                  </p>
                  <p className="text-white text-opacity-50 leading-7 mb-5">
                    This TOU is governed by the laws of the State of New York,
                    without reference to its principles of conflict of laws.
                    Subject to the foregoing arbitration requirements, any
                    claim, suit, or other proceeding brought between the You and
                    Piston under this TOU shall be adjudicated exclusively in
                    the state and federal courts sitting in New York, New York,
                    and You hereby expressly submit to such jurisdiction for the
                    final resolution thereof.{" "}
                  </p>
                  <p className="text-white text-opacity-50 leading-7 mb-5">
                    {" "}
                    The Platform may not be used or accessed from or in any
                    jurisdiction that does not give effect to all provisions of
                    this TOU, including without limitation this paragraph.
                  </p>
                </li>
                <li>
                  <p className="mb-4 text-white text-opacity-70">
                    Independent Contractors
                  </p>
                  <p className="text-white text-opacity-50 leading-7 mb-3">
                    The relationship between You and Piston under this TOU is
                    that of independent contractors, and nothing herein or in
                    Your use of the Platform shall be construed as creating any
                    joint venture, partnership, employment, or agency
                    relationship.
                  </p>
                </li>
                <li>
                  <p className="mb-4 text-white text-opacity-70">
                    Security and Compliance{" "}
                  </p>
                  <p className="text-white text-opacity-50 leading-7 mb-5">
                    Piston reserves the right to view, monitor, and record Your
                    activity on the Platform without notice or permission from
                    You. Piston’s provision of the Platform is subject to
                    existing laws and legal process, and nothing contained
                    herein shall restrict or reduce Piston’s ability to comply
                    with governmental, court, and law enforcement requests or
                    requirements involving Your use of the Platform or
                    information provided to or gathered by Piston with respect
                    to such use.
                  </p>
                </li>
                <li>
                  <p className="mb-4 text-white text-opacity-70">
                    Export Controls{" "}
                  </p>
                  <p className="text-white text-opacity-50 leading-7 mb-5">
                    You represent and warrant that You are not: (a) located in a
                    country that is subject to a U.S. government embargo or
                    sanction; or (b) listed on any U.S. government list of
                    prohibited or restricted parties. You hereby agree that (i)
                    You will comply with all applicable sanctions and export
                    control laws, and (ii) You are solely responsible for
                    ensuring that the Platform is used, disclosed, and/or
                    accessed only in accordance with all applicable sanctions
                    and export control laws.
                  </p>
                </li>
                <li>
                  <p className="mb-4 text-white text-opacity-70">
                    Severability and Waiver
                  </p>
                  <p className="text-white text-opacity-50 leading-7 mb-5">
                    If any part of this TOU is determined to be invalid or
                    unenforceable pursuant to court order or other operation of
                    applicable law, such provision shall be deemed reformed or
                    severed from this TOU to the minimum extent required by such
                    order or law, and the remainder of this TOU shall continue
                    in full force and effect to the maximum extent permitted
                    under applicable law.{" "}
                  </p>
                  <p className="text-white text-opacity-50 leading-7 mb-5">
                    Piston’s failure to insist on strict performance of any of
                    this TOU will not operate as a waiver of any subsequent
                    default or failure of performance by You. No waiver by
                    Piston of any right under this TOU will be deemed to be
                    either a waiver of any other right or provision or a waiver
                    of that same right or provision at any other time.
                  </p>
                </li>
                <li>
                  <p className="mb-4 text-white text-opacity-70">
                    Injunctive Relief
                  </p>
                  <p className="text-white text-opacity-50 leading-7 mb-5">
                    You acknowledge that any breach, threatened or actual, of
                    this TOU would cause irreparable injury to Piston not
                    readily quantifiable as money damages, such that Piston
                    would not have an adequate remedy at law. You therefore
                    agree that Piston shall be entitled, in addition to other
                    available remedies, to seek and be awarded an injunction or
                    other appropriate equitable relief from a court of competent
                    jurisdiction restraining any such breach of Your
                    obligations, without the necessity of posting bond or other
                    security.
                  </p>
                </li>
                <li>
                  <p className="mb-4 text-white text-opacity-70">
                    Changes to Terms
                  </p>
                  <p className="text-white text-opacity-50 font-bold leading-7 mb-5">
                    Please note that Piston reserves the right to change the
                    terms and conditions of this TOU by posting a revised set of
                    terms or mailing and/or e-mailing notice thereof to You. In
                    addition, Piston may add, modify, or delete any aspect,
                    component, or feature of the Platform, but Piston is not
                    under any obligation to add any upgrade, enhancement, or
                    modification. Your continued use of the Platform following
                    any announced change will be deemed as conclusive acceptance
                    of any change to the TOU. Accordingly, please review the TOU
                    on a periodic basis.
                  </p>
                  <p className="text-white text-opacity-50 leading-7 mb-5">
                    If You have any questions regarding this TOU, please contact
                    Piston at{" "}
                    <Link
                      to="mailto:support@usepiston.com"
                      className="text-primary"
                    >
                      support@usepiston.com
                    </Link>{" "}
                    and{" "}
                    <Link to="tel:415 335-7801" className="text-primary">
                      (415) 335-7801.
                    </Link>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default TermsAndConditions;
