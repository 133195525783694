import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Navigation, Pagination } from "swiper/modules";

import { BsPersonCircle, BsQuote } from "react-icons/bs";

const ClientReview = ({ reviewData, title, subTitle }) => {
  return (
    <>
      <section className="relative w-full pt-16 pb-8 review-sec bg-black-form">
        <div className="lg:max-w-screen-lg md:max-w-screen-md max-w-screen-sm px-5 xl:px-0 w-full flex flex-wrap items-center justify-center mx-auto mb-5">
          <div className="flex justify-center flex-col w-full gap-2">
            {title}
            <p className="text-center text-white text-opacity-50">{subTitle}</p>
          </div>
        </div>

        <div className="lg:max-w-screen-xl md:max-w-screen-md max-w-screen-sm px-5 xl:px-0 w-full flex flex-wrap items-center justify-center mx-auto">
          <div className="w-full py-5">
            <Swiper
              slidesPerView={3}
              spaceBetween={30}
              centeredSlides={true}
              loop={true}
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                360: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                  centeredSlides: false,
                },
                640: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                  centeredSlides: false,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                  centeredSlides: true,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
              }}
              navigation={true}
              modules={[Autoplay, Navigation, Pagination]}
              className="mySwiper clientReview"
            >
              {reviewData.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div
                      className={`w-full flex flex-col bg-black_primary rounded-2xl px-10 pt-5 active-brown min-h-[440px] max-h-[440px] border border-gray-600 relative`}
                    >
                      <div className="flex pb-2">
                        <BsQuote className="text-dark-red text-6xl" />
                      </div>
                      <div className="max-h-64 overflow-y-auto no-scrollbar mb-2">
                        <h6 className="text-base font-regular leading-8 mb-5 text-opacity-50 text-white">
                          {item.desc}
                        </h6>
                      </div>
                      <div className="flex gap-4 items-center pt-2 pb-6 px-10 absolute bottom-0 w-full left-0 rounded-br-2xl rounded-bl-2xl">
                        <div className="icon">
                          <BsPersonCircle className="h-10 w-10" />
                        </div>
                        <div className="user-details">
                          <h6 className="text-white text-md font-bold">
                            {item.username}
                          </h6>
                          <p className="text-white text-opacity-50">
                            {item.designation}
                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};

export default ClientReview;
