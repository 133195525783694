import React, { useEffect, useState } from 'react'
import BannerBg from '../../assets/images/banner-bg.png'
import { getPage } from '../../models/CommonModel';
import { useParams } from 'react-router-dom';
import config from '../../config/config';
import Layout from '../layout/Layout';
import { Helmet } from 'react-helmet';

const Page = () => {
    const {slug} = useParams();

    const [page, setPage] = useState([]);
    useEffect(() => {
      const fetchPage = async () => {
        try {
          const result = await getPage(slug);
          if (result.status) {  
            setPage(result.data);
          }
        } catch (error) {
          console.error(error);
          setPage({pageTitle: '404 Page not found!'});
        }
      };
  
      fetchPage();
    }, [slug]);

    const bannerStyle = {
        backgroundImage: `url(${page?.page?.banner ? config.assetURL + page?.page?.banner : BannerBg})`,
        height:"700px",
        marginTop: "0px",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
    };

  return (
    <Layout settings={page?.settings}>
      <Helmet>
        <title>{`${page?.page?.pageTitle} - ${page?.settings?.appName}`}</title>
      </Helmet>

        <section className='relative xl:max-h-[700px] max-h-[500px] banner-bg overflow-hidden flex items-center z-10 w-full' style={bannerStyle}>
            <div className='lg:max-w-screen-xl md:max-w-screen-md max-w-screen-sm px-5 xl:px-0 w-full flex flex-wrap items-center justify-between mx-auto'>
                <div className="w-auto">
                    <h1 className='xl:text-7xl md:text-5xl text-2xl font-bold text-white pe-40'>{page?.page?.pageTitle}</h1>
                    
                </div>
            </div>
        </section> 

        <section className='relative w-full py-10'>
            <div className='lg:max-w-screen-xl md:max-w-screen-md max-w-screen-sm px-5 xl:px-0 w-full flex flex-wrap items-center justify-center mx-auto mb-20'>
                <div className="flex justify-center flex-col w-full">

                    <div dangerouslySetInnerHTML={{ __html: page?.page?.content }}></div>
                    {/* More Content */}
                </div>
            </div>
        </section>
    </Layout>
  )
}

export default Page