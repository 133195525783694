import React from 'react'

const PartnerSupport = ({features, home}) => {

    // const features = [
    //     {
    //         title :"24/7 Customer Centric Excellence",
    //         desc1 : "At Piston, we prioritize your satisfaction and success. That's why our dedicated customer support team is available 24/7, ready to assist you whenever you need it.",
    //         desc2 : "Whether you have a question, need assistance, or want to provide feedback, we're here for you around the clock."
    //     },
    //     {
    //         title :"Acceptance Anywhere, Anytime",
    //         desc1 : "With Piston's fuel cards, you have the freedom to fuel up anywhere, anytime. Our cards are widely accepted at fuel stations across the country, giving you unparalleled convenience and flexibility. ",
    //         desc2 : "Say goodbye to restrictive fueling options and hello to the freedom to choose where you fill up."
    //     },
    //     {
    //         title :"Seamless User Experience",
    //         desc1 : "We understand that your time is valuable, which is why we've designed Piston's platform with ease of use in mind. Whether you're managing your fleet on the go or diving into detailed analytics, our intuitive interface makes it easy to navigate and get things done efficiently. ",
    //         desc2 : "Say hello to a seamless user experience that puts you in control."
    //     },
    // ]

  return (
    <section className='relative w-full py-10'>
            <div className='lg:max-w-screen-xl md:max-w-screen-md max-w-screen-sm px-5 xl:px-0 w-full flex flex-wrap items-center justify-center mx-auto xl:mb-20 mb-10'>
                <div className="flex justify-center w-3/4">
                    <h2 className='xl:text-6xl md:text-4xl text-xl font-bold text-center'>{home?.featureHeading}</h2>
                </div>
            </div>

            <div className='lg:max-w-screen-xl md:max-w-screen-md max-w-screen-sm px-5 xl:px-0 w-full flex flex-wrap items-center justify-center mx-auto'>
                <div className="grid lg:grid-cols-3 md:grid-cols-auto gap-5">
                    {
                        features.map((item) => {
                            return (                                
                                <div key={item.id} className="bg-black_primary hover:bg-back_gray transition-all duration-150 p-5 rounded-2xl flex flex-col gap-5">
                                    <h4 className='text-golden xl:text-5xl md:text-3xl text-2xl font-bold w-full'>{item.title}</h4>
                                    <p className='mb-4'>{item.desc1}</p>
                                    <p className='mb-4'>{item.desc2}</p>
                                </div>                                
                            )
                        })
                    }
                </div>
            </div>
    </section>
  )
}

export default PartnerSupport