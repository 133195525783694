import React, { useEffect, useRef, useState } from "react";
import ActionButton from "../buttons/ActionButton";
import InputMask from 'react-input-mask';
import axios from "axios";
import ReCAPTCHA from 'react-google-recaptcha';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    companyName: "",
    email: "",
    number: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [formTouched, setFormTouched] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [verified, setVerified] = useState(false);
  const [fields, setFields] = useState(formData);

  const captchaRef = useRef(null)

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) {
        newErrors.name = "Name is required";
      } else if (/\d/.test(formData.name)) {
        newErrors.name = "Name cannot contain numbers";
    }
    if (!formData.companyName.trim())
        newErrors.companyName = "Company name is required";
    
    if (!formData.email.trim() || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email))
        newErrors.email = "Valid email is required";

    // if (!formData.number.trim().replace(/[^0-9]/g, "") || formData.number.length !== 10)
    //     newErrors.number = "Phone number must be 10 digits";

    if (!formData.number.trim()) {
        newErrors.number = "Phone number is required";
    } else if (formData.number.replace(/\D/g, '').length !== 10) {
        newErrors.number = "Phone number must be exactly 10 digits";
    } else if (formData.number.startsWith('0')) {
        newErrors.number = "Phone number cannot start with 0";
    }
    
    if (!formData.message.trim()) 
        newErrors.message = "Message cannot be empty";

    if (!verified) newErrors.recaptcha = "Please complete the reCAPTCHA";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if no errors
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });

    if (formTouched) {
        setErrors((prevErrors) => ({
            ...prevErrors,
            [e.target.id]: undefined,
        }));
    }

  };

  const handleSubmit = async(e) => {
    e.preventDefault();
  

    setFormTouched(true); // Mark the form as submitted
    if (validateForm()) {
        
        setIsSubmitting(true); // Disable the button
        fields.token = captchaRef.current.getValue();

        const url = process.env.REACT_APP_API_URL;
        const site_key = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
        const bearer_token = process.env.REACT_APP_API_BEARER_TOKEN;
        
        // console.log(site_key);
        
        axios.post(url, {
          name: formData.name,
          company_name: formData.companyName,
          email: formData.email,
          phone: formData.number,
          message: formData.message
        }, {
          headers: {
              'Authorization': 'Bearer '+bearer_token,
            }
          })
          // .then((response) => response.text())
          .then((result) => console.log(result))
          .catch((error) => console.error(error));
        
        await sendEmail(formData);
       clearForm();
    }
  }

  const clearForm = () => {
    setSuccessMessage('Your message has been sent successfully!');
      // Reset form
      setFormData({
          name: '',
          companyName: '',
          email: '',
          number: '',
          message: ''
      });
      setFormTouched(false);
      setErrors({});
      setIsSubmitting(false); // Re-enable the button
      setTimeout(() => {
        setSuccessMessage('');      
      }, 5000)
      captchaRef.current.reset();
      setVerified(false);

  }


  const sendEmail = async (data) => {
    // Simulate email processing
    return new Promise((resolve) => {
        setTimeout(() => {
            const emailBody = `
                Name: ${data.name}
                Company Name: ${data.companyName}
                Email: ${data.email}
                Phone Number: ${data.number}
                Message: ${data.message}
            `;
            console.log("Sending Email with data: ", emailBody); // Replace with email API logic
            // clearForm();
            resolve();
        }, 1000);
    });
};


  return (
    <>
      <div className="contact-sec relative w-full gradient-border-custom gradient-border-light pb-16 bg-black" id="contact-form">
        <section className="relative w-full pt-12">
          <div className="lg:max-w-screen-xl md:max-w-screen-md max-w-screen-sm px-5 xl:px-0 w-full flex flex-wrap items-center justify-center mx-auto">
            <div className="flex justify-center flex-col w-full gap-5">
              <h2 className="lg:text-4xl md:text-2xl text-xl font-medium text-center">
                Contact Us
              </h2>
            </div>
          </div>
        </section>
        <section className="relative max-h-full lg:py-15 pb-12 pt-8 overflow-hidden flex items-center z-10 w-full">
          <div className="lg:max-w-screen-lg md:max-w-screen-md max-w-screen-sm px-5 xl:px-0 w-full flex flex-wrap items-center justify-between mx-auto">
            <div className="w-full mx-auto bg-black lg:py-20 py-10 lg:px-16 px-5 rounded-2xl border border-gray-custom">
              <form
                className="md:mt-0 mt-2 space-y-5 lg:w-full"
                onSubmit={handleSubmit}
              >
                <div className="grid md:grid-cols-2 grid-cols-1 gap-5">
                  <div className="w-full">
                    <label
                      htmlFor="name"
                      className="block mb-2 text-base text-white"
                    >
                      Name
                    </label>
                    <input
                      id="name"
                      type="text"
                      className={`w-full sm:text-sm border ${
                        errors.name ? "border-red-500" : "border-gray-600"
                      } p-2 rounded-md bg-black-form focus:bg-black placeholder-gray-500 focus:outline-none cursor-default caret-white text-white`}
                      placeholder="Your Full Name"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                    {formTouched && errors.name && (
                      <p className="text-red-500 text-sm mt-1">{errors.name}</p>
                    )}
                  </div>
                  <div className="w-full">
                    <label
                      htmlFor="companyName"
                      className="block mb-2 text-base text-white"
                    >
                      Company Name
                    </label>
                    <input
                      id="companyName"
                      type="text"
                      className={`w-full sm:text-sm border ${
                        errors.companyName
                          ? "border-red-500"
                          : "border-gray-600"
                      } p-2 rounded-md bg-black-form focus:bg-black placeholder-gray-500 focus:outline-none cursor-default caret-white text-white`}
                      placeholder="Your Company Name"
                      value={formData.companyName}
                      onChange={handleInputChange}
                    />
                    {formTouched && errors.companyName && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.companyName}
                      </p>
                    )}
                  </div>
                </div>
                <div className="grid md:grid-cols-2 grid-cols-1 gap-5">
                  <div className="w-full">
                    <label
                      htmlFor="email"
                      className="block mb-2 text-base text-white"
                    >
                      Email Address
                    </label>
                    <input
                      id="email"
                      type="text"
                      className={`w-full sm:text-sm border ${
                        errors.email ? "border-red-500" : "border-gray-600"
                      } p-2 rounded-md bg-black-form focus:bg-black placeholder-gray-500 focus:outline-none cursor-default caret-white text-white`}
                      placeholder="Your Email Address"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                    {formTouched && errors.email && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.email}
                      </p>
                    )}
                  </div>
                  <div className="w-full">
                    <label
                      htmlFor="number"
                      className="block mb-2 text-base text-white"
                    >
                      Phone Number
                    </label>
                    <InputMask 
                      id="number"
                      mask="(999) 999-9999"
                      placeholder="Your Phone Number"
                      className={`w-full sm:text-sm border ${
                        errors.number ? "border-red-500" : "border-gray-600"
                      } p-2 rounded-md bg-black-form focus:bg-black placeholder-gray-500 focus:outline-none cursor-default caret-white text-white`}
                      value={formData.number}
                      onChange={handleInputChange}
                    />

                    {formTouched && errors.number && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.number}
                      </p>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-1">
                  <div className="w-full">
                    <textarea
                      id="message"
                      rows="3"
                      className={`mt-3 md:mt-0 w-full sm:text-sm border ${
                        errors.message ? "border-red-500" : "border-gray-600"
                      } p-2 rounded-md bg-black-form focus:bg-black placeholder-gray-500 focus:outline-none focus:ring-0 ring-gray-600 ring-opacity-25 cursor-default caret-white text-white`}
                      placeholder="Your Message or Any Specific Questions"
                      value={formData.message}
                      onChange={handleInputChange}
                    />
                    {formTouched && errors.message && (
                      <p className="text-red-500 text-sm">
                        {errors.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="grid grid-cols-1 recaptcha">
                    <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} onChange={() => setVerified(true)} ref={captchaRef} />
                    
                    {formTouched && errors.recaptcha && <p className="text-red-500 text-sm mt-1">{errors.recaptcha}</p>}
                </div>

                <div className="grid grid-cols-1">
                  <div className="md:w-auto w-full">
                    <button
                      type="submit"
                      className="text-white bg-dark-red hover:bg-[#9a1c1f] px-4 py-3 rounded-md"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? 'Processing...' : 'Contact our team'}
                    </button>
                  </div>
                </div>

                {successMessage && (
                  <p className="text-green-500 text-sm mt-3">
                    {successMessage}
                  </p>
                )}
              </form>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ContactUs;
