import { Route, Routes } from "react-router-dom";
import Layout from "./components/layout/Layout";
import HomepageView from "./components/pages/Homepage/HomepageView";
import "./globals.css";
import GasStationView from "./components/pages/GasStation/GasStationView";
import ContactView from "./components/pages/Contact/ContactView";
import ComingSoon from "./components/common-sections/ComingSoon";
import TermsAndConditionsView from "./components/pages/TermsAndConditions/TermsAndConditionsView";

function App() {
  return (
    <Layout>
      {/* <HomepageView />
        <IndustryPageView />
        <FaqPageView />         */}

      <Routes>
        <Route exact path="/" element={<HomepageView />} />
        <Route exact path="/gas-station" element={<GasStationView />} />
        <Route exact path="/contact-us" element={<ContactView />} />
        <Route exact path="/coming-soon" element={<ComingSoon />} />
        <Route
          exact
          path="/terms-and-conditions"
          element={<TermsAndConditionsView />}
        />
      </Routes>
    </Layout>
  );
}

export default App;
