import React, { useEffect, useRef, useState } from 'react';
import Banner from './sections/Banner';
import FuelCards from './sections/FuelCards';
import GetStartedCta from './sections/GetStartedCta';
import PartnerSupport from './sections/PartnerSupport';
import ClientReview from './sections/ClientReview';
import ContactUs from './sections/ContactUs';
import HowItWorks from './sections/HowItWorks';
import StartSavings from './sections/StartSavings';
import { getHome } from '../../../models/CommonModel';
import Layout from '../../layout/Layout';
import { Helmet } from 'react-helmet';

const HomepageView = () => {

  const [page, setPage] = useState([]);
  useEffect(() => {
    const fetchPage = async () => {
      try {
        const result = await getHome();

        setPage(result.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchPage();
  }, []);

  const contactRef = useRef(document.getElementById('contact'));

  return (
    <Layout settings={page?.settings}>
      <Helmet>
        <title>{`For Fleets - ${page?.settings?.appName}`}</title>
      </Helmet>

      <Banner home={page.home ?? {}} />
      <FuelCards home={page.home ?? {}} />
      <HowItWorks home={page.home ?? {}} />
      <StartSavings home={page.home ?? {}} />
      <GetStartedCta home={page.home ?? {}} toRef={contactRef} />
      <PartnerSupport features={page.features ?? []} home={page.home ?? {}} />
      <ClientReview testimonials={page.testimonials ?? []} home={page.home ?? {}} />
      <ContactUs home={page.home ?? {}} />
    </Layout>
  )
}

export default HomepageView