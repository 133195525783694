import React from 'react'
import PropTypes from 'prop-types';

const ActionButton = ({ text, onClick, className}) => {

  return (
    <>
        <button 
            type="button" 
            className={`rounded-md bg-${className}} lg:text-base text-sm font-bold px-2 lg:py-3 py-2 min-w-40 border-2 hover:bg-${className} hover:border-2 transition-all duration-150 ${className}`}
            onClick={onClick} >
                              
            <span>{text}</span>

        </button>
    </>
  )
}

ActionButton.propTypes = {
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    className: PropTypes.string,
};
  
// ActionButton.defaultProps = {
//     onClick: () => {},
//     className:""
// };


export default ActionButton