import React, { useEffect, useState } from 'react';
import { getMerchant } from '../../models/CommonModel';
import Layout from '../layout/Layout';
import { Helmet } from 'react-helmet';
import Banner from './Homepage/sections/Banner';
import ContactUs from './Homepage/sections/ContactUs';

const Merchant = () => {

  const [page, setPage] = useState([]);
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const result = await getMerchant();

        setPage(result.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchClients();
  }, []);
  

  return (
    <Layout settings={page?.settings}>
      <Helmet>
        <title>{`For Merchants - ${page?.settings?.appName}`}</title>
      </Helmet>

      <Banner home={page.home ?? {}} />
      <ContactUs home={page.home ?? {}} />
    </Layout>
  )
}

export default Merchant