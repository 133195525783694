import React from 'react'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'

const AccordionItems = ({ item, isOpen, onClick }) => {
  return (
    <>
    {/* <div className="accordion-item">
      <button className="accordion-title" onClick={onClick}>
        {item.title}
      </button>
      {isOpen && (
        <div className="accordion-content">
          {item.content}
        </div>
      )}
    </div> */}
 
    <div id="accordion-collapse" className='mb-2 bg-black_primary rounded-lg border border-gray-600'>
        <h2 id="accordion-collapse-heading-1" >
            <button type="button" 
            onClick={onClick}
            className="flex items-center justify-between w-full p-3 bg-black_primary rounded-lg border border-gray-600 transition-all duration-200">
            <span className='font-bold text-sm'>{item.title}</span>
                {!isOpen ? <BsChevronDown />:<BsChevronUp />}
            </button>
        </h2>
        {isOpen && (
          <div id="accordion-collapse-body-1" className="transition-all duration-300" aria-labelledby="accordion-collapse-heading-1">
              <div className="px-3 py-5 bg-black_primary rounded-b-lg">
              <p className="mb-2 text-gray-300">{item.content}</p>
              {/* <p className="text-gray-500 dark:text-gray-400">Check out this guide to learn how to <a href="/docs/getting-started/introduction/" className="text-blue-600 dark:text-blue-500 hover:underline">get started</a> and start developing websites even faster with components on top of Tailwind CSS.</p> */}
              </div>
          </div>
        )}
    </div>
    </>
  )
}

export default AccordionItems