import React, { useState } from 'react'
import Logo from '../../assets/images/logo.svg'
import { Link } from 'react-router-dom'
// import 'flowbite';

const Header = () => {
    const [isMenu, setIsMenu] = useState(false);

  return (
    <nav className="bg-transparent fixed z-20 w-full top-5">
        <div className="lg:max-w-screen-xl md:max-w-screen-md max-w-screen-sm px-5 xl:px-0 flex flex-wrap items-center justify-between mx-auto ">
            <Link to="/" className="flex items-center space-x-3">
                <img src={Logo} className="h-20" alt="Piston Logo" />
            </Link>
            <button onClick={() => setIsMenu(!isMenu)} type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-expanded="false">
                <span className="sr-only">Open main menu</span>
                <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
                </svg>
            </button>
            <div className={`w-full lg:block lg:w-auto `+(! isMenu ? 'hidden' : '')} id="navbar-dropdown">
                <ul className="flex lg:flex-row flex-col items-center gap-5 text-base py-1.5 ps-3 mt-4 border border-gray-600 lg:rounded-full rounded-lg bg-black_primary md:mt-0">
                    <li>
                        <Link to="/" className="block py-0 px-3 text-white hover:text-custom-red transition-all md:bg-transparent">For Fleets</Link>
                    </li>
                    <li>
                        <Link to="/merchant" className="block py-0 px-3 text-white hover:text-custom-red transition-all md:bg-transparent">For Merchants</Link>
                    </li>
                    <li>
                        <a href="#contact" className='text-white bg-dark-red hover:bg-red-800 rounded-full text-base px-5 py-2.5 text-center me-1.5 flex items-center'>
                            Get Started 
                            <svg className="w-2.5 h-2.5 ms-2.5 -rotate-90 opacity-50" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                <path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
                            </svg>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
  )
}

export default Header