import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Layout = ({children}) => {
  const { pathname } = useLocation(); // Automatically scrolls to top whenever pathname changes
  useEffect(() => {window.scrollTo(0, 0);}, [pathname]);

  return (
    <>
        <Header />
        <main className=''>
            {children}
        </main>
        <Footer />
    </>
  )
}

export default Layout