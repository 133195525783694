import React, { useEffect, useRef, useState } from "react";
import ActionButton from "../buttons/ActionButton";
import InputMask from "react-input-mask";
import axios from "axios";

const GetStartedCta = ({ title, available }) => {
  const [isAvailable, setIsAvailable] = useState(available);

  const [formData, setFormData] = useState({
    name: "",
    companyName: "",
    email: "",
    number: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [formTouched, setFormTouched] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    } else if (/\d/.test(formData.name)) {
      newErrors.name = "Name cannot contain numbers";
    }
    if (!formData.companyName.trim())
      newErrors.companyName = "Company name is required";

    if (
      !formData.email.trim() ||
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)
    )
      newErrors.email = "Valid email is required";

    // if (!formData.number.trim().replace(/[^0-9]/g, "") || formData.number.length !== 10)
    //     newErrors.number = "Phone number must be 10 digits";

    if (!formData.number.trim()) {
      newErrors.number = "Phone number is required";
    } else if (formData.number.replace(/\D/g, "").length !== 10) {
      newErrors.number = "Phone number must be exactly 10 digits";
    } else if (formData.number.startsWith("0")) {
      newErrors.number = "Phone number cannot start with 0";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if no errors
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });

    if (formTouched) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [e.target.id]: undefined,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setFormTouched(true); // Mark the form as submitted
    if (validateForm()) {
      setIsSubmitting(true); // Disable the button

      const url = process.env.REACT_APP_API_URL;
      const site_key = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
      const bearer_token = process.env.REACT_APP_API_BEARER_TOKEN;

      // console.log(site_key);

      axios
        .post(
          url,
          {
            name: formData.name,
            company_name: formData.companyName,
            email: formData.email,
            phone: formData.number,
            message: formData.message,
          },
          {
            headers: {
              Authorization: "Bearer " + bearer_token,
            },
          }
        )
        // .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.error(error));

      await sendEmail(formData);
      clearForm();
    }
  };

  const clearForm = () => {
    setSuccessMessage("Your message has been sent successfully!");
    // Reset form
    setFormData({
      name: "",
      companyName: "",
      email: "",
      number: "",
      message: "",
    });
    setFormTouched(false);
    setErrors({});
    setIsSubmitting(false); // Re-enable the button
    setTimeout(() => {
      setSuccessMessage("");
    }, 5000);
  };

  const sendEmail = async (data) => {
    // Simulate email processing
    return new Promise((resolve) => {
      setTimeout(() => {
        const emailBody = `
                Name: ${data.name}
                Company Name: ${data.companyName}
                Email: ${data.email}
                Phone Number: ${data.number}
                Message: ${data.message}
            `;
        console.log("Sending Email with data: ", emailBody); // Replace with email API logic
        // clearForm();
        resolve();
      }, 1000);
    });
  };
  return (
    <>
      <section className="relative overflow-hidden flex items-center z-10 w-full cta-sec lg:py-36 py-16 border-b border-gray-500 border-opacity-30 bg-black">
        <div className="lg:max-w-screen-lg md:max-w-screen-md max-w-screen-sm px-5 xl:px-0 w-full flex lg:flex-row flex-col items-center justify-between mx-auto gap-5">
          <div className="lg:w-1/2 w-full">
            <h4 className="lg:text-4xl md:text-2xl text-xl font-bold text-dark-red mb-4">
              {" "}
              Get In Touch!
            </h4>
            <h2 className="lg:text-4xl md:text-2xl text-xl font-medium text-white text-opacity-75 mb-4">
              {title}
            </h2>
            {isAvailable && (
              <>
                <p className="font-medium text-white text-opacity-50">
                  Bringing all your investments to
                </p>
                <p className="font-medium text-white text-opacity-50">
                  Wealth simple means better access to exclusive{" "}
                  <span className="underline text-white">perks.</span>
                </p>
              </>
            )}
          </div>
          <div className="lg:w-1/2 w-full">
            <form
              className="md:mt-0 mt-2 space-y-5 lg:w-full border border-gray-custom lg:p-10 p-5 bg-black rounded-2xl"
              onSubmit={handleSubmit}
            >
              <div className="grid md:grid-cols-2 grid-cols-1 gap-5">
                <div className="w-full">
                  <label
                    htmlFor="name"
                    className="block mb-2 text-base text-white"
                  >
                    Name
                  </label>
                  <input
                    id="name"
                    type="text"
                    className={`w-full sm:text-sm border ${
                      errors.name ? "border-red-500" : "border-gray-600"
                    } p-2 rounded-md bg-black-form focus:bg-black placeholder-gray-500 focus:outline-none cursor-default caret-white text-white`}
                    placeholder="Your Full Name"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                  {formTouched && errors.name && (
                    <p className="text-red-500 text-sm mt-1">{errors.name}</p>
                  )}
                </div>
                <div className="w-full">
                  <label
                    htmlFor="companyName"
                    className="block mb-2 text-base text-white"
                  >
                    Company Name
                  </label>
                  <input
                    id="companyName"
                    type="text"
                    className={`w-full sm:text-sm border ${
                      errors.companyName ? "border-red-500" : "border-gray-600"
                    } p-2 rounded-md bg-black-form focus:bg-black placeholder-gray-500 focus:outline-none cursor-default caret-white text-white`}
                    placeholder="Your Company Name"
                    value={formData.companyName}
                    onChange={handleInputChange}
                  />
                  {formTouched && errors.companyName && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.companyName}
                    </p>
                  )}
                </div>
              </div>
              <div className="grid md:grid-cols-2 grid-cols-1 gap-5">
                <div className="w-full">
                  <label
                    htmlFor="email"
                    className="block mb-2 text-base text-white"
                  >
                    Email Address
                  </label>
                  <input
                    id="email"
                    type="text"
                    className={`w-full sm:text-sm border ${
                      errors.email ? "border-red-500" : "border-gray-600"
                    } p-2 rounded-md bg-black-form focus:bg-black placeholder-gray-500 focus:outline-none cursor-default caret-white text-white`}
                    placeholder="Your Email Address"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                  {formTouched && errors.email && (
                    <p className="text-red-500 text-sm mt-1">{errors.email}</p>
                  )}
                </div>
                <div className="w-full">
                  <label
                    htmlFor="number"
                    className="block mb-2 text-base text-white"
                  >
                    Phone Number
                  </label>
                  <InputMask
                    id="number"
                    mask="(999) 999-9999"
                    placeholder="Your Phone Number"
                    className={`w-full sm:text-sm border ${
                      errors.number ? "border-red-500" : "border-gray-600"
                    } p-2 rounded-md bg-black-form focus:bg-black placeholder-gray-500 focus:outline-none cursor-default caret-white text-white`}
                    value={formData.number}
                    onChange={handleInputChange}
                  />

                  {formTouched && errors.number && (
                    <p className="text-red-500 text-sm mt-1">{errors.number}</p>
                  )}
                </div>
              </div>

              <div className="grid grid-cols-1">
                <div className="md:w-auto w-full">
                  <button
                    type="submit"
                    className="text-white bg-dark-red hover:bg-[#9a1c1f] px-4 py-3 rounded-md"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Processing..." : "Contact us"}
                  </button>
                </div>
              </div>

              {successMessage && (
                <p className="text-green-500 text-sm mt-3">{successMessage}</p>
              )}
            </form>
            {/* <form className="md:mt-0 mt-2 space-y-5 lg:w-full border border-gray-custom p-10 bg-black rounded-2xl">
                        <div className="grid md:grid-cols-2 grid-cols-1 gap-5">
                            <div className="w-full">                                
                                <label htmlFor="email" className="block mb-2 text-base text-white">Name</label>
                                <input id="" className="w-full sm:text-sm border border-gray-600 p-2 rounded-md bg-black-form focus:bg-black placeholder-gray-500 focus:outline-none cursor-default caret-white text-white" placeholder="Your Full Name" />
                            </div>
                            <div className="w-full">                                
                                <label htmlFor="email" className="block mb-2 text-base text-white">Entity Name</label>
                                <input id="" className="w-full sm:text-sm border border-gray-600 p-2 rounded-md bg-black-form focus:bg-black placeholder-gray-500 focus:outline-none cursor-default caret-white text-white" placeholder="Your Company Name" />
                            </div>                            
                        </div>
                        <div className="grid md:grid-cols-2 grid-cols-1 gap-5">
                            <div className="w-full">                                
                                <label htmlFor="email" className="block mb-2 text-base text-white">Email Address</label>
                                <input id="" className="w-full sm:text-sm border border-gray-600 p-2 rounded-md bg-black-form focus:bg-black placeholder-gray-500 focus:outline-none cursor-default caret-white text-white" placeholder="Your Email Address" />
                            </div>
                            <div className="w-full">                                
                                <label htmlFor="email" className="block mb-2 text-base text-white">Phone Number</label>
                                <input id="" className="w-full sm:text-sm border border-gray-600 p-2 rounded-md bg-black-form focus:bg-black placeholder-gray-500 focus:outline-none cursor-default caret-white text-white" placeholder="Your Phone Number" />
                            </div>                            
                        </div>
                        <div className="grid grid-cols-1">
                            <div className="md:w-auto w-full"> 
                              <ActionButton 
                                text="Contact Us"
                                onClick={null}
                                className={"text-white bg-dark-red hover:bg-[#9a1c1f]"}
                                />
                            </div>
                        </div>

                    </form> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default GetStartedCta;
