import React from 'react'
import PropTypes from 'prop-types';

const PrimaryButton = ({ text, onClick = () => {}, className = "", icon, isDisabled = false, loading = false, type = 'button'}) => {

  return (
    <>
        <button disabled={isDisabled || loading}
            type={type} 
            className={`flex items-center justify-center gap-3 focus:outline-none text-${className} bg-${className} hover:bg-${className} font-bold xl:text-2xl text-base rounded-lg px-5 py-3 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900 md:min-w-60 min-w-max transition-all duration-100 border-2 border-purple hover:border-gray-600 ${className}`}
            onClick={onClick} >
                
                <span>{loading ? 'Sending...' : text}</span>
                {icon}

        </button>
    </>
  )
}

PrimaryButton.propTypes = {
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    className: PropTypes.string,
};

export default PrimaryButton