import React from 'react'
import BannerBg from '../../../../assets/images/map-bg.png'


const GetStartedCta = ({home}) => {

  const bannerStyle = {
    backgroundImage: `url(${BannerBg})`,
    height:"280px",
    marginTop: "0px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
};

  return (
    <section className='relative max-h-[300px] overflow-hidden flex items-center z-10 w-full cta-sec' style={bannerStyle}>
          <div className='lg:max-w-screen-xl md:max-w-screen-md max-w-screen-sm px-5 xl:px-0 w-full flex flex-wrap items-center justify-between mx-auto gap-5'>
              <div className="lg:w-1/2 w-full">
                  <h2 className='xl:text-5xl md:text-3xl text-xl font-bold text-custom-green'>
                      {home?.callToActionHeading}
                  </h2>
              </div>
              <div className="lg:w-auto w-full">
                  <a href="/#contact" className='flex items-center justify-center gap-3 focus:outline-none text-text-white bg-purple hover:bg-black_primary bg-text-white bg-purple hover:bg-black_primary hover:bg-text-white bg-purple hover:bg-black_primary font-bold xl:text-2xl text-base rounded-lg px-5 py-3 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900 md:min-w-60 min-w-max transition-all duration-100 border-2 border-purple hover:border-gray-600 text-white bg-purple hover:bg-black_primary'>
                    Get Started
                  </a>
              </div>
          </div>
      </section>
  )
}

export default GetStartedCta