import React, { useRef, useState } from 'react'
import ContactBg from '../../../../assets/images/contact-bg.png'
import PrimaryButton from '../../../buttons/PrimaryButton';
import { BsRocketTakeoff } from 'react-icons/bs';
import ReCAPTCHA from 'react-google-recaptcha';
import { sendMessage } from '../../../../models/CommonModel';


const ContactUs = ({home}) => {
    const inputs = {
        name: '',
        email: '',
        phone: '',
        companyName: '',
        message: '',
    };

    const [verified, setVerified] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fields, setFields] = useState(inputs);
    const [isSent, setIsSent] = useState(false);
    const [error, setError] = useState('');

    const captchaRef = useRef(null)

  const submit = async(e) => {
    e.preventDefault();
    setLoading(true);

    fields.token = captchaRef.current.getValue();

    let response;
    try {
      response = await sendMessage(fields);
      if (response.status) {
            setFields(inputs);
            setIsSent(true);
            setError('');

            setTimeout(() => setIsSent(false), 3000);
        } else {
            setError(response.message ?? 'Something went wrong! Please try again.');
        }
    } catch (error) {
      response = error?.response?.data;
      setError(response?.message ?? 'Something went wrong! Please try again.');
    }

    setTimeout(() => setError(''), 5000);
    captchaRef.current.reset();
    setVerified(false);

    setLoading(false);
  }

    const bannerStyle = {
        backgroundImage: `url(${ContactBg})`,
        height:"100%",
        marginTop: "0px",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundAttachment:"fixed"
    };

  return (
    <>
        <section className='relative w-full py-10' id="contact" >
            <div className='lg:max-w-screen-xl md:max-w-screen-md max-w-screen-sm px-5 xl:px-0 w-full flex flex-wrap items-center justify-center mx-auto'>
                <div className="flex justify-center flex-col w-3/4 gap-5">
                    <h2 className='xl:text-6xl md:text-4xl text-xl font-bold text-center'>{home?.contactHeading}</h2>

                    <div dangerouslySetInnerHTML={{ __html: home?.contactDescription }}></div>
                </div>
            </div>
        </section>
        <section className='relative lg:max-h-[900px] max-h-full lg:py-20 py-10 overflow-hidden flex items-center z-10 w-full about-sec' style={bannerStyle}>
            <div className='lg:max-w-screen-xl md:max-w-screen-md max-w-screen-sm px-5 xl:px-0 w-full flex flex-wrap items-center justify-between mx-auto'>
                <div className="w-5/6 mx-auto bg-black_primary lg:p-20 p-10 rounded-2xl border border-dark-red shadow shadow-dark-red">
                    <form className="md:mt-0 mt-2 space-y-5 lg:w-full" onSubmit={submit}>
                        <div className="grid md:grid-cols-2 grid-cols-1 gap-5">
                            <div className="w-full">                                
                                <label htmlFor="name" className="block mb-2 text-base text-gray-900 text-white">Name</label>
                                <input id="name" name="name" value={fields?.name} onChange={(e) => {setFields((prev) => ({ ...prev, name: e.target.value })); }} className="w-full sm:text-sm border dark:border-gray-600 border-gray-300 p-2 rounded-md dark:bg-transparent focus:bg-black bg-black text-white text-black dark:placeholder-gray-500 placeholder-gray-500 focus:outline-none cursor-default dark:caret-white caret-black" placeholder="Your Full Name" required />
                            </div>
                            <div className="w-full">                                
                                <label htmlFor="companyName" className="block mb-2 text-base text-gray-900 text-white">Company Name</label>
                                <input id="companyName" name="companyName" value={fields?.companyName} onChange={(e) => {setFields((prev) => ({ ...prev, companyName: e.target.value })); }} className="w-full sm:text-sm border dark:border-gray-600 border-gray-300 p-2 rounded-md dark:bg-transparent focus:bg-black bg-black text-white text-black dark:placeholder-gray-500 placeholder-gray-500 focus:outline-none cursor-default dark:caret-white caret-black" placeholder="Your Company Name" required />
                            </div>                            
                        </div>
                        <div className="grid md:grid-cols-2 grid-cols-1 gap-5">
                            <div className="w-full">                                
                                <label htmlFor="email" className="block mb-2 text-base text-gray-900 text-white">Email Address</label>
                                <input id="email" name="email" value={fields?.email} onChange={(e) => {setFields((prev) => ({ ...prev, email: e.target.value })); }} className="w-full sm:text-sm border dark:border-gray-600 border-gray-300 p-2 rounded-md dark:bg-transparent focus:bg-black bg-black text-white text-black dark:placeholder-gray-500 placeholder-gray-500 focus:outline-none cursor-default dark:caret-white caret-black" placeholder="Your Email Address" required />
                            </div>
                            <div className="w-full">                                
                                <label htmlFor="phone" className="block mb-2 text-base text-gray-900 text-white">Phone Number</label>
                                <input id="phone" name="phone" value={fields?.phone} onChange={(e) => {setFields((prev) => ({ ...prev, phone: e.target.value })); }} className="w-full sm:text-sm border dark:border-gray-600 border-gray-300 p-2 rounded-md dark:bg-transparent focus:bg-black bg-black text-white text-black dark:placeholder-gray-500 placeholder-gray-500 focus:outline-none cursor-default dark:caret-white caret-black" placeholder="Your Phone Number" required />
                            </div>                            
                        </div>
                        <div className="grid grid-cols-1">
                            <div className="w-full">   
                                <textarea id="message" name="message" value={fields?.message} onChange={(e) => {setFields((prev) => ({ ...prev, message: e.target.value })); }} rows="3" className="mt-3 md:mt-0 w-full sm:text-sm border dark:border-gray-600 border-gray-300 p-2 rounded-md dark:bg-transparent focus:bg-black bg-black text-white text-black dark:placeholder-gray-500 placeholder-gray-500 focus:outline-none cursor-default dark:caret-white caret-black" placeholder="Your Message or Any Specific Questions" required></textarea>
                            </div>                    
                        </div>
                        <div className="grid grid-cols-1 recaptcha">
                            <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} onChange={() => setVerified(true)} ref={captchaRef} />
                        </div>
                        <div className="grid grid-cols-1">
                            <div className="md:w-auto w-full"> 
                                {!isSent && <PrimaryButton 
                                    text="Request a Demo"
                                    type="submit"
                                    icon={<BsRocketTakeoff />}
                                    className={"text-white bg-purple hover:bg-black_primary text-base"}
                                    isDisabled={!verified}
                                    loading={loading}
                                />}
                            </div>
                        </div>

                        {isSent && <div className="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400" role="alert">
                            <span className="font-bold">Request sent successfully! We will soon get in touch.</span>
                        </div>}

                        {error && <div className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                            <span className="font-bold">{error}</span>
                        </div>}

                        <p className='lg:w-1/2 w-full'>Your information is safe with us. We will never share or sell your data to third parties.</p>
                    </form>

                </div>
            </div>
        </section>
    </>
  )
}

export default ContactUs