import React from "react";
import TermsAndConditions from "../../common-sections/TermsAndConditions";

const TermsAndConditionsView = () => {
  return (
    <>
      <section className="relative w-full lg:pt-24 pb-20 py-5 contact-inner-sec overflow-hidden bg-black gradient-border-custom gradient-border-light"></section>
      <TermsAndConditions />
    </>
  );
};

export default TermsAndConditionsView;
