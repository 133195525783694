import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

// import required modules
import { Navigation } from 'swiper/modules';

import { BsPersonCircle, BsQuote } from 'react-icons/bs';
import config from '../../../../config/config';


const ClientReview = ({testimonials, home}) => {

    // const reviewData = [
    //     {
    //         desc : "Piston's fuel cards have transformed the way we manage our fleet. The 24/7 customer support is unparalleled, and the innovative features have helped us optimize our operations like never before. With Piston, we've seen significant savings and increased efficiency. Highly recommended!",
    //         username: "Johnathan R.",
    //         designation:"CEO, Swift Logistics"
    //     },
    //     {
    //         desc : "Piston's fuel cards have transformed the way we manage our fleet. The 24/7 customer support is unparalleled, and the innovative features have helped us optimize our operations like never before. With Piston, we've seen significant savings and increased efficiency. Highly recommended!",
    //         username: "Johnathan R.",
    //         designation:"CEO, Swift Logistics"
    //     },
    //     {
    //         desc : "Piston's fuel cards have transformed the way we manage our fleet. The 24/7 customer support is unparalleled, and the innovative features have helped us optimize our operations like never before. With Piston, we've seen significant savings and increased efficiency. Highly recommended!",
    //         username: "Johnathan R.",
    //         designation:"CEO, Swift Logistics"
    //     },
    //     {
    //         desc : "Piston's fuel cards have transformed the way we manage our fleet. The 24/7 customer support is unparalleled, and the innovative features have helped us optimize our operations like never before. With Piston, we've seen significant savings and increased efficiency. Highly recommended!",
    //         username: "Johnathan R.",
    //         designation:"CEO, Swift Logistics"
    //     },
    //     {
    //         desc : "Piston's fuel cards have transformed the way we manage our fleet. The 24/7 customer support is unparalleled, and the innovative features have helped us optimize our operations like never before. With Piston, we've seen significant savings and increased efficiency. Highly recommended!",
    //         username: "Johnathan R.",
    //         designation:"CEO, Swift Logistics"
    //     },
    // ];

  return (
    <section className='relative w-full py-10'>
            <div className='lg:max-w-screen-xl md:max-w-screen-md max-w-screen-sm px-5 xl:px-0 w-full flex flex-wrap items-center justify-center mx-auto mb-20'>
                <div className="flex justify-center flex-col w-3/4 gap-5">
                    <h2 className='xl:text-6xl md:text-4xl text-xl font-bold text-center'>{home?.testimonialHeading}</h2>

                    <div dangerouslySetInnerHTML={{ __html: home?.testimonialDescription }}></div>
                </div>
            </div>

            <div className='lg:max-w-screen-xl md:max-w-screen-md max-w-screen-sm px-5 xl:px-0 w-full flex flex-wrap items-center justify-center mx-auto'>
                <div className="w-full py-5">
                    <Swiper
                        slidesPerView={3}
                        spaceBetween={30}
                        centeredSlides={true}
                        loop={true}
                        pagination={{
                          clickable: true,
                        }}
                        breakpoints={{
                            360: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                                centeredSlides:false,
                            },
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                                centeredSlides:false,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 40,
                                centeredSlides:true
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 50,
                            },
                        }}
                        navigation={{clickable:true}}
                        modules={[Navigation]}
                        className="mySwiper clientReview"
                    >
                        {
                            testimonials.map((item) => {
                                return (
                                    <SwiperSlide key={item.id}>
                                        <div className={`w-full flex flex-col bg-black_primary rounded-2xl p-5 active-brown`}>
                                            <div className="flex">
                                                <BsQuote className='text-custom-green text-6xl' />
                                            </div>
                                            <h6 className='text-xl font-bold mb-5'>{item.testimony}</h6>

                                            <div className='flex gap-4 items-center'>
                                                <div className="icon">
                                                    {item.image ? <img src={config.assetURL + item.image} alt={item.name} style={{width:'40px', height: '40px', borderRadius: '50%'}} /> : <BsPersonCircle className='h-10 w-10' />}
                                                </div>
                                                <div className="user-details">
                                                    <h6 className='text-custom-green text-xl font-bold'>{item.name}</h6>
                                                    <p className='text-custom-green opacity-50'>{item.designation}</p>
                                                </div>
                                            </div>

                                        </div>
                                    </SwiperSlide>
                                )
                            })
                        }
                        
                    </Swiper>
                </div>
            </div>
    </section>
  )
}

export default ClientReview