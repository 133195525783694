import React from 'react'
import '../../../../assets/js/script'
import { BsFuelPump, BsTruck } from 'react-icons/bs'
import PrimaryButton from '../../../buttons/PrimaryButton'
import saving from '../../../../assets/images/saving.jpg';

const StartSavings = ({home}) => {
  return (
    <section className='relative w-full lg:py-16 py-5'>
        <div className='lg:max-w-screen-xl md:max-w-screen-md max-w-screen-sm px-5 xl:px-0 w-full flex flex-wrap items-center justify-between mx-auto xl:mb-20 mb-0'>
            <div className="w-full flex flex-wrap items-center lg:gap-0 gap-5">
                <div className="lg:w-1/2 w-full xl:pe-10 p-0">
                    <h2 className='xl:text-6xl md:text-4xl text-xl text-golden font-bold mb-10'>{ home?.savingsTitle }</h2>
                    <div dangerouslySetInnerHTML={{ __html: home?.savingsDescription }}></div>
                </div>
                <div className="lg:w-1/2 w-full bg-black_primary p-5 rounded-2xl">

                    <img src={saving} />

                    {/* <div className="custom-range-slider w-full mb-5">
                        <h6 className='xl:text-2xl text-base font-bold mb-4'>Choose Fleet Size</h6>
                        <div className="flex items-center gap-3">
                            <div className="icon">
                                <BsTruck className='text-2xl text-gray-400' />
                            </div>                            
                            <div className="range-slider">
                                <div id="slider_thumb" className="range-slider_thumb"></div>
                                <div className="range-slider_line">
                                    <div id="slider_line" className="range-slider_line-fill"></div>
                                </div>
                                <input id="slider_input" className="range-slider_input" type="range" value="20" min="0" max="100" />
                            </div>
                        </div>
                    </div>
                    <div className="custom-range-slider w-full mb-5">
                        <h6 className='xl:text-2xl text-base font-bold mb-4'>Select Fill-Ups Per Week</h6>
                        <div className="w-full">
                            <form className="form-vertical">
                                <div className="control-group">
                                    <div className="controls">
                                        <input type="radio" name="optionsRadios" id="optionsRadios1" value="option1" />
                                        <label className="radio" htmlFor="optionsRadios1">
                                            1
                                        </label>
                                        <input type="radio" name="optionsRadios" id="optionsRadios2" value="option2" />
                                        <label className="radio" htmlFor="optionsRadios2">
                                            2
                                        </label>
                                        <input type="radio" name="optionsRadios" id="optionsRadios3" value="option3" />
                                        <label className="radio" htmlFor="optionsRadios3">
                                            3
                                        </label>
                                        <input type="radio" name="optionsRadios" id="optionsRadios4" value="option4" />
                                        <label className="radio" htmlFor="optionsRadios4">
                                            4
                                        </label>
                                        <input type="radio" name="optionsRadios" id="optionsRadios5" value="option5" />
                                        <label className="radio" htmlFor="optionsRadios5">
                                            5
                                        </label>
                                        <input type="radio" name="optionsRadios" id="optionsRadios6" value="option6" />
                                        <label className="radio" htmlFor="optionsRadios6">
                                            6
                                        </label>
                                        <input type="radio" name="optionsRadios" id="optionsRadios7" value="option7" />
                                        <label className="radio" htmlFor="optionsRadios7">
                                            7
                                        </label>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                    <div className="custom-range-slider w-full mb-5">
                        <h6 className='xl:text-2xl text-base font-bold mb-4'>Choose Gallons Per Fill-Up</h6>
                        <div className="flex items-center gap-3">
                            <div className="icon">
                                <BsFuelPump className='text-2xl text-gray-400' />
                            </div>                            
                            <div className="range-slider">
                                <div id="slider_thumb" className="range-slider_thumb"></div>
                                <div className="range-slider_line">
                                    <div id="slider_line" className="range-slider_line-fill"></div>
                                </div>
                                <input id="slider_input" className="range-slider_input" type="range" value="20" min="0" max="100" />
                            </div>
                        </div>
                    </div>
                    <div className="w-full mb-5 rounded-2xl bg-black p-5 text-center">
                        <h4 className='xl:text-5xl md:text-3xl text-xl font-bold text-gray-600 mb-5'>Annual Savings</h4>
                        <h2 className='xl:text-7xl md:text-4xl text-xl font-bold text-custom-green'>$ 65,275</h2>
                    </div> */}
                    <div className="w-full">
                        {/* <PrimaryButton 
                            text="Start Saving"
                            onClick={null}
                            className={"text-white bg-purple hover:bg-black_primary w-full"}
                        /> */}

                        <a href="/#contact" style={{marginTop: '15px'}} className='flex items-center justify-center gap-3 focus:outline-none text-text-white bg-purple hover:bg-black_primary bg-text-white bg-purple hover:bg-black_primary hover:bg-text-white bg-purple hover:bg-black_primary font-bold xl:text-2xl text-base rounded-lg px-5 py-3 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900 md:min-w-60 min-w-max transition-all duration-100 border-2 border-purple hover:border-gray-600 text-white bg-purple hover:bg-black_primary'>
                            Start Saving
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default StartSavings