import React, { useState } from 'react'
import AccordionItems from './AccordionItems';

const Accordion = ({ items }) => {

    const [openIndex, setOpenIndex] = useState(0);

    const handleClick = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

  return (
    <>
    <div className="accordion">
      {items.map((item, index) => (
        <AccordionItems
          key={index}
          item={item}
          isOpen={openIndex === index}
          onClick={() => handleClick(index)}
        />
      ))}
    </div>
    </>
  )
}

export default Accordion