import React from 'react'
import HowitworksBg from '../../../../assets/images/how-it-works.png'
import config from '../../../../config/config'


const HowItWorks = ({home}) => {

    // const stepsData = [
    //     {
    //         subHeading:"STEP 001",
    //         heading:"Open the Piston Pay app and generate a QR code"
    //     },
    //     {
    //         subHeading:"STEP 002",
    //         heading:"Get it scanned at the cashier."
    //     },
    //     {
    //         subHeading:"STEP 003",
    //         heading:"Fuel up & Hit the road!"
    //     },
    // ]

  let i = 0;
  return (
    <section className='relative w-full lg:py-16 py-5'>
        <div className='lg:max-w-screen-xl md:max-w-screen-md max-w-screen-sm px-5 xl:px-0 w-full flex flex-wrap items-center justify-between mx-auto xl:mb-20 mb-0'>
            <div className="w-full flex flex-wrap items-center">
                <div className="lg:w-1/2 w-full pe-10">
                    <h2 className='xl:text-6xl md:text-4xl text-xl text-golden font-bold mb-10'>{home?.howItWorksTitle}</h2>

                    {
                        home.howItWorks?.map((item) => {
                            return (
                                <div className='w-full flex flex-col mb-12' key={i++}>
                                    <p className='text-dark-red text-base roboto-mono mb-3'>{item.step}</p>
                                    <h2 className='xl:text-5xl md:text-2xl font-bold'>{item.description}</h2>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="lg:w-1/2 w-full">
                    <img src={config.assetURL + home?.howItWorksImage} alt="{home?.howItWorksTitle}" className='w-full object-cover xl:h-full' />
                </div>
            </div>
        </div>
    </section>
  )
}

export default HowItWorks