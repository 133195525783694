import React from 'react'
import pistonDashboard from '../../assets/images/dashboard-new.png';


const PistonDashboard = ({desc}) => {
  return (
    <>
        <section className='relative w-full lg:pt-16 py-5 dashboard-sec overflow-hidden bg-black-form'>

            <div className='max-w-screen-lg w-full flex lg:flex-row flex-col items-center justify-center px-5 xl:px-0 mx-auto gap-3 py-16 gradient-border-custom-top gradient-border-light'>
                <div className="flex justify-center lg:w-1/2 w-full">
                    <img src={pistonDashboard} alt="" className='w-full' />
                </div>
                <div className="flex justify-center lg:w-1/2 w-full ps-3">
                    <p className='lg:text-4xl md:text-2xl text-xl font-bold text-white text-opacity-75 capitalize lg:text-left text-center'>{desc}</p>
                </div>
            </div>
        </section>
    </>
  )
}

export default PistonDashboard