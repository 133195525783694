import axios from "axios";

const createAxiosInstance = () => {
  const headers = {
    "Content-Type": "application/json",
    // "API-KEY": process.env.REACT_APP_API_KEY,
  };

  return axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 30000, // 30 sec
    headers: headers,
  });
};

// Usage:
const instance = createAxiosInstance();

// CancelToken source for cancelling requests
axios.CancelToken.source();

// Function to make a GET request
const GET = async (url) => {
  try {
    const response = await instance.get(url);

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

// Function to make a POST request
const POST = async (url, data) => {
  try {
    const response = await instance.post(url, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

// Upload File Post
const UploadPost = async (url, formData) => {
  try {
    const response = await instance.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export { GET, POST };
