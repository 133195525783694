import React from 'react'
import Logo from '../../assets/images/logo.svg'
import { Link } from 'react-router-dom'

const Footer = ({settings}) => {

  return (
    <>
      <section className='relative flex items-center z-10 w-full bg-black'>
        <div className="lg:max-w-screen-xl md:max-w-screen-md max-w-screen-sm px-5 xl:px-0 w-full flex flex-wrap items-center justify-between mx-auto">
          <div className="grid md:grid-cols-4 w-full">
              <div className='w-full md:border-r-2 border-r-0 border-gray-600 lg:py-14 md:py-8 py-4'>
                <Link to="/"><img src={Logo} alt={settings?.appName} className='w-auto' /></Link>
              </div>

              <div className='w-full footer-links lg:px-14 md:px-5 px-0 md:border-r-2 border-r-0 border-gray-600 lg:py-14 md:py-8 py-4'>
                <div className="heading mb-6">
                  <h6 className='text-sm uppercase text-golden'>Pages</h6>
                </div>
                <ul className='flex flex-col gap-5'>
                  <li><Link to="/">For Fleets</Link></li>
                  <li><Link to="/merchant">For Merchants</Link></li>
                  <li><a href="#contact">Contact</a></li>
                </ul>
              </div>
              <div className='w-full footer-links lg:px-14 md:px-5 px-0 md:border-r-2 border-r-0 border-gray-600 lg:py-14 md:py-8 py-4'>
                <div className="heading mb-6">
                  <h6 className='text-sm uppercase text-golden'>NeTwork</h6>
                </div>
                <ul className='flex flex-col gap-5'>
                  {settings?.socialBlog && <li><a href={settings?.socialBlog} target='_blank'>Blog</a></li>}
                  {settings?.socialPress && <li><a href={settings?.socialPress} target='_blank'>Press</a></li>}
                  {settings?.socialLinkedIn && <li><a href={settings?.socialLinkedIn} target='_blank'>Linkedin</a></li>}
                  {settings?.socialFacebook && <li><a href={settings?.socialFacebook} target='_blank'>Facebook</a></li>}
                  {settings?.socialInstagram && <li><a href={settings?.socialInstagram} target='_blank'>Instagram</a></li>}
                </ul>
              </div>
              <div className='w-full footer-links lg:px-14 md:px-5 px-0 lg:py-14 md:py-8 py-4'>
                <div className="heading mb-6">
                  <h6 className='text-sm uppercase text-golden'>LEGAL</h6>
                </div>
                <ul className='flex flex-col gap-5'>
                  <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                  <li><Link to="/terms-and-conditions">Terms & Conditions</Link></li>
                </ul>
              </div>
          </div>
        </div>
      </section>
      <section className='relative flex items-center z-10 w-full bg-black border-t-2 border-gray-600'>
        <div className="lg:max-w-screen-xl md:max-w-screen-md max-w-screen-sm px-5 xl:px-0 w-full flex flex-wrap items-center justify-between mx-auto">
          <div className='w-full footer-links py-10'>
            <ul className='flex gap-5'>
              <li>© {new Date().getFullYear()} Piston</li>
              {/* <li><a href="#">Cookies & Data</a></li> */}
            </ul>
          </div>
        </div>
      </section>
    </>
  )
}

export default Footer