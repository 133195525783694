import React from 'react'
import Logo from '../../assets/images/logo.svg'
import { Link } from 'react-router-dom'
import { BsFacebook, BsInstagram, BsLinkedin } from 'react-icons/bs'

const Footer = () => {

  return (
    <>
      <footer className='relative flex items-center z-10 w-full bg-black footer-sec pt-16 overflow-hidden'>
        <div className='w-full background-blur-custom border-t border-gray-500 border-opacity-50'>
          <div className="lg:max-w-screen-lg md:max-w-screen-md max-w-screen-sm px-5 xl:px-0 w-full flex flex-wrap items-center justify-between mx-auto">
            <div className="grid md:grid-cols-5 w-full">
                <div className='w-full border-r-0 border-gray-600 lg:py-14 md:py-8 py-4'>
                  <img src={process.env.REACT_APP_BASE_URL+`/assets/images/logo.png`} alt="" className='' />
                </div>

                <div className='w-full footer-links lg:px-10 md:px-5 px-0 border-r-0 border-gray-600 lg:py-14 md:py-8 py-4'>
                  <div className="heading mb-4">
                    <h6 className='text-sm uppercase text-white font-bold'>Pages</h6>
                  </div>
                  <ul className='flex flex-col gap-4 text-white text-opacity-50'>
                    <li><Link to="/">Home</Link></li>
                    <li><a href="#contact-form">Partner with us</a></li>
                    <li><Link to="https://partner.pistonpay.com" target='_blank'>Partner Login</Link></li>
                  </ul>
                </div>
                <div className='w-full footer-links lg:px-10 md:px-5 px-0 border-r-0 border-gray-600 lg:py-14 md:py-8 py-4'>
                  <div className="heading mb-4">
                    <h6 className='text-sm uppercase text-white font-bold'>NeTwork</h6>
                  </div>
                  
                  <ul className='flex gap-4 mt-4 text-white text-opacity-50'>
                    <li><Link to="/"><BsLinkedin className='text-lg'/></Link></li>
                    <li><Link to="/"><BsFacebook className='text-lg'/></Link></li>
                    <li><Link to="/"><BsInstagram className='text-lg'/></Link></li>
                  </ul>
                </div>
                <div className='w-full footer-links lg:px-10 md:px-5 px-0 lg:py-14 md:py-8 py-4'>
                  <div className="heading mb-4">
                    <h6 className='text-sm uppercase text-white font-bold'>LEGAL</h6>
                  </div>
                  <ul className='flex flex-col gap-4 text-white text-opacity-50'>
                    <li><Link to="/coming-soon">Privacy Policy</Link></li>
                    <li><Link to="/coming-soon">Terms & Conditions</Link></li>
                  </ul>
                </div>
                <div className='w-full footer-links lg:px-10 md:px-5 px-0 lg:py-14 md:py-8 py-4'>
                  <div className="heading mb-4">
                    <h6 className='text-sm uppercase text-white font-bold'>Coming Soon</h6>
                  </div>
                  <ul className='flex flex-col gap-4 text-white text-opacity-50'>
                    <li><Link to="/coming-soon">Blog</Link></li>
                    <li><Link to="/coming-soon">Press</Link></li>
                  </ul>
                </div>
            </div>
          </div>
        </div>
      </footer>
      <section className='relative flex items-center z-10 w-full bg-black'>
        <div className="lg:max-w-screen-lg md:max-w-screen-md max-w-screen-sm px-5 xl:px-0 w-full flex flex-wrap items-center justify-between mx-auto">
          <div className='w-full footer-links py-10'>
            <ul className='flex gap-5'>
              <li>© {new Date().getFullYear()} Piston</li>
              <li><Link to="/">Cookies & Data</Link></li>
            </ul>
          </div>
        </div>
      </section>
    </>
  )
}

export default Footer