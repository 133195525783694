import React from "react";

const PartnerSupport = ({
  numberOfItems,
  arrayData,
  title,
  wrapperClass,
  gridClass,
  paddingTop,
  onlyGasSec,
}) => {
  // console.log("LINE 17",pathName);

  return (
    <>
      <section
        className={`relative w-full ${paddingTop} lg:pb-32 pb-16 gradient-border-custom gradient-border-light bg-black-form min-h-screen flex flex-col justify-center ${onlyGasSec} overflow-hidden`}
      >
        <div className="lg:max-w-screen-lg md:max-w-screen-md max-w-screen-sm px-5 xl:px-0 w-full flex flex-wrap items-center justify-center mx-auto xl:mb-12 mb-10">
          <div className="flex justify-center w-auto">
            {/* {pathName?.pathName === GAS_STATION_URL
                        ? <h2 className='md:text-4xl text-xl font-medium text-center'>By enrolling yourself into the <span className='shadow-red text-dark-red'> Piston Network </span><br/> you get access to: </h2>
                        : <h2 className='md:text-4xl text-xl font-medium text-center'>Your Trusted Partner for Seamless Fleet <br/>Management and <span className='shadow-red text-dark-red'> Support </span></h2>
                        } */}
            {title}
          </div>
        </div>

        <div className={`${wrapperClass}`}>
          <div className="lg:max-w-screen-lg md:max-w-screen-md max-w-screen-sm px-5 xl:px-0 w-full flex flex-wrap items-center justify-center mx-auto relative z-[1]">
            <div className={`grid ${gridClass} gap-8`}>
              {arrayData
                .slice(0, numberOfItems)
                .map(({ img, tag, Desc, title }, index) => (
                  <div
                    className="bg-black lg:hover:bg-black_primary hover:bg-black transition-all duration-150 py-12 px-8 rounded-2xl flex flex-col border border-gray-custom"
                    key={index}
                  >
                    <div className="border border-gray-500 rounded-md flex justify-center items-center h-20 w-20 shadow-red mb-5">
                      <img src={img} alt={tag} />
                    </div>
                    <h4 className="text-dark-red md:text-2xl text-xl font-medium w-full mb-1">
                      {title}
                    </h4>
                    {/* <p className='font-medium text-white text-opacity-50'>{Desc}</p> */}
                    {Desc}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PartnerSupport;
