import React from "react";
import InnerBanner from "../../common-sections/InnerBanner";
import Accordion from "../../common-sections/Accordion";
import { BsSearch } from "react-icons/bs";
import GetStartedCta from "../../common-sections/GetStartedCta";
import ContactUs from "../../common-sections/ContactUs";
import BannerBg from "../../../assets/images/gas-station-banner.png";
import { useState } from "react";
import { useEffect } from "react";
import PartnerSupport from "../../common-sections/PartnerSupport";
import { useLocation } from "react-router-dom";
import Support4 from "../../../assets/images/support-4.png";
import Support5 from "../../../assets/images/support-5.png";
import Support3 from "../../../assets/images/support-3.png";
import Support7 from "../../../assets/images/support-7.png";
import PistonDashboard from "../../common-sections/PistonDashboard";
import VideoSec from "../../common-sections/VideoSec";
import ClientReview from "../../common-sections/ClientReview";

const GasStationView = ({ item, isOpen, onClick }) => {
  const [isMobile, setIsMobile] = useState(false);
  const checkMobile = () => {
    setIsMobile(window.innerWidth <= 768); // Adjust the width as needed
  };
  useEffect(() => {
    checkMobile(); // Check on component mount

    // Add event listener for window resize
    window.addEventListener("resize", checkMobile);

    // Cleanup listener on component unmount
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  const reviewData = [
    {
      desc: "PistonPay added 20,000 gallons per month to my sales! All at no cost to me.",
      username: "Paul S",
      designation: "Owner, One Stop Fuel",
    },
    {
      desc: "Piston’s team is very easy to work with. Not only did they deliver on the additional revenue, their customer support is always there to help us out, and answer any questions. Their dashboard makes reconciliation very easy!",
      username: "Ramesh S",
      designation: "Owner, Parkway Express",
    },
    {
      desc: "Joining the PistonPay network has resulted in meaningful increase in our gas sales, as well as our C Store sales. The iPad is very easy to use and train our team on.",
      username: "Richard M",
      designation: "Regional Manager, Red Square",
    },
    {
      desc: "PistonPay added 20,000 gallons per month to my sales! All at no cost to me.",
      username: "Paul S",
      designation: "Owner, One Stop Fuel",
    },
    {
      desc: "Piston’s team is very easy to work with. Not only did they deliver on the additional revenue, their customer support is always there to help us out, and answer any questions. Their dashboard makes reconciliation very easy!",
      username: "Ramesh S",
      designation: "Owner, Parkway Express",
    },
    {
      desc: "Joining the PistonPay network has resulted in meaningful increase in our gas sales, as well as our C Store sales. The iPad is very easy to use and train our team on.",
      username: "Richard M",
      designation: "Regional Manager, Red Square",
    },
  ];

  const cardData = [
    {
      title: "Access new Fleets",
      Desc: (
        <p className="font-medium text-white text-opacity-50">
          By joining the Piston network you get access to{" "}
          <span className="text-white">Piston fleets</span>, allowing you to
          dramatically increase your sales.
        </p>
      ),
      tag: "Fuel",
      img: Support4,
    },
    {
      title: "Simplified relationship management",
      desc1:
        "Save yourself from the hassle of dealing with multiple house accounts. Piston streamlines everything into one relationship. Making accounting a breeze",
      Desc: (
        <p className="font-medium text-white text-opacity-50">
          Save yourself from the hassle of dealing with multiple house accounts.
          Piston streamlines everything into
          <span className="text-white"> one relationship.</span> Making
          accounting a breeze.
        </p>
      ),
      tag: "Cardless",
      img: Support5,
    },
    {
      title: "Eliminate Fraud",
      desc1:
        "Every transaction approved is 100% backed by Piston's guarantee. No need to worry about fraud management!",
      Desc: (
        <p className="font-medium text-white text-opacity-50">
          Every transaction approved is{" "}
          <span className="text-white"> 100% backed</span> by Piston's
          guarantee. No need to worry about fraud management!
        </p>
      ),
      tag: "Fraud",
      img: Support3,
    },
    {
      title: "Transparent and realtime reporting",
      desc1:
        "Using the Piston Partner portal, you can get access to realtime reporting, customized reports and detailed analytics.",
      Desc: (
        <p className="font-medium text-white text-opacity-50">
          Using the Piston Partner portal, you can get access to{" "}
          <span className="text-white"> realtime reporting</span>, customized
          reports and detailed analytics.
        </p>
      ),
      tag: "Fraud",
      img: Support7,
    },
  ];

  return (
    <>
      <InnerBanner
        title="Boost Your Revenue Overnight"
        index={2}
        desc="Partnering with Piston dramatically increases your fuel sales & C store sales"
        indexDesc={5}
      />

      <div>
        {" "}
        <PartnerSupport
          title={
            <h2 className="md:text-4xl text-xl font-medium text-center">
              By enrolling yourself into the{" "}
              <span className="shadow-red text-dark-red"> Piston Network </span>
              <br /> you get access to:{" "}
            </h2>
          }
          numberOfItems={4}
          arrayData={cardData}
          wrapperClass="background-blur-custom py-16 border border-gray-600 border-opacity-50 rounded-2xl relative z-[1]"
          gridClass="lg:grid-cols-2 md:grid-cols-auto"
          paddingTop="lg:pt-16 pt-5"
          onlyGasSec="only-gas-sec relative"
        />
      </div>

      <GetStartedCta title="Join Fresno 76 in boosting their sales by 30,000 gallons a month" />

      <PistonDashboard desc="Transparent, easy to use dashboard to view your sales in realtime." />

      <VideoSec
        src="https://www.youtube.com/embed/Uwa4VWfJm1Y?si=VNLqYaAy2nqVdA5J"
        title="Learn how C-Way Gas Station in Utah increased their sales by 20,000 Gallons a month using Piston"
        index={9}
        paddingTop="lg:pt-16 py-16"
      />

      <ContactUs />

      <ClientReview
        reviewData={reviewData}
        title={
          <h2 className="lg:text-4xl md:text-2xl text-xl font-medium text-center">
            Join the PistonPay network to boost your{" "}
            <span className="text-dark-red shadow-red relative">Revenue </span>
          </h2>
        }
        subTitle={"Hear what our partners have to say"}
      />
    </>
  );
};

export default GasStationView;
