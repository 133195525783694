import React from 'react'
// import AboutBg from '../../../../assets/images/about-bg.png'
import config from '../../../../config/config'

const FuelCards = ({home}) => {

  return (
    <section className='relative w-full lg:py-16 py-5'>
        <div className='lg:max-w-screen-xl md:max-w-screen-md max-w-screen-sm px-5 xl:px-0 w-full flex flex-wrap items-center justify-between mx-auto mb-20'>
            <div className="flex justify-center w-5/6 mx-auto mb-5">
                {/* <h2 className='xl:text-6xl md:text-4xl text-xl font-bold text-center'>Tap into our nationwide network of truck stops with the tip of your finger. </h2> */}
                <h2 className='xl:text-6xl md:text-4xl text-xl font-bold text-center'>{home?.aboutTitle}</h2>
            </div>
            <div className="flex justify-center w-4/6 mx-auto">
                {/* <p className='text-center'>Rev up your engines and take charge of your fleet's performance with Piston! We're here to supercharge your operations by providing a seamless solution for managing fuel, fleet, and employee expenses, all through one dynamic card.</p> */}
                <p className='text-center'>{home?.aboutDescription}</p>
            </div>
        </div>
        <div className='lg:max-w-screen-xl md:max-w-screen-md max-w-screen-sm px-5 xl:px-0 w-full flex flex-wrap items-center justify-between mx-auto'>
            <div className="w-full">
                <img src={config.assetURL + home?.aboutImage} alt="" className='w-full object-cover' />
            </div>
        </div>
    </section>
  )
}

export default FuelCards