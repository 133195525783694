import React from "react";
import ContactUs from "../../common-sections/ContactUs";

const ContactView = () => {
  return (
    <>
      <section className="relative w-full lg:pt-24 pb-20 py-5 contact-inner-sec overflow-hidden bg-black gradient-border-custom gradient-border-light"></section>
      <ContactUs />
    </>
  );
};

export default ContactView;
