import React, { useEffect, useState } from 'react'
import AboutUs from './AboutUs';
import PartnerSupport from './PartnerSupport';
import Support1 from "../../assets/images/support-1.png";
import Support2 from "../../assets/images/support-2.png";
import Support3 from "../../assets/images/support-3.png";
import { useRef } from 'react';
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import { BsCircleFill } from 'react-icons/bs';

gsap.registerPlugin(ScrollTrigger);

const GsapContainer = () => {

    const [activePanel, setActivePanel] = useState(null); 
    const [isMobile, setIsMobile] = useState(false);

    const cardData = [
        {
          title: "Fuel Savings",
          desc1: "Industry leading savings of upto 40 Cents off/gallon",
          tag: "Fuel",
          img: Support1,
        },
        {
          title: "Cardless Solution",
          desc1:
            "Piston's cardless technology frees you from the hassles of card management.",
          tag: "Cardless",
          img: Support2,
        },
        {
          title: "Fraud Protection",
          desc1:
            "Leverage Piston's proprietary fraud prevention engine to eliminate fraudulent spend.",
          tag: "Fraud",
          img: Support3,
        },
        {
          title: "Fraud Protection",
          desc1:
            "Leverage Piston's proprietary fraud prevention engine to eliminate fraudulent spend.",
          tag: "Fraud",
          img: Support3,
        },
      ];

    const sections = useRef([]);

    useEffect(() => {
        const panels = sections.current;
    
        let tops = panels.map((panel) =>
        ScrollTrigger.create({
            trigger: panel,
            start: "top top",
        })
        );

        panels.forEach((panel, index) => {
        ScrollTrigger.create({
            trigger: panel,
            start: () => panel.offsetHeight < window.innerHeight ? "top top" : "bottom bottom",
            pin: true,
            snap: 1,
            pinSpacing: false,
            onEnter: () => setActivePanel(index),
            onLeaveBack: () => setActivePanel(index),
        });
        });

        // Cleanup on unmount
        return () => {
          tops.forEach((trigger) => trigger.kill());
          ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
        }
    }, []);

    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 991); // Adjust the width as needed
    };
    useEffect(() => {
      checkMobile(); // Check on component mount
  
      // Add event listener for window resize
      window.addEventListener('resize', checkMobile);
  
      // Cleanup listener on component unmount
      return () => window.removeEventListener('resize', checkMobile);
    }, []);


  return (
    <>
      <div ref={(el) => (sections.current[0] = el)} className={`panel ${activePanel === 0 ? "active-panel" : ""}`}
      >
        <AboutUs 
        title={<h2 className='lg:text-4xl md:text-2xl text-xl font-medium'>Leverage Piston's cardless solution to drive <br/> unparalleled savings for you</h2>} />
        {activePanel === 0 ? (
          <div className="icon-overlay">
            <BsCircleFill className="icon text-dark-red" />
          </div>
        ) : (
          <div className="icon-overlay">
            <BsCircleFill className="icon text-gray-600" />
          </div>
        )}
        
      </div>
      <div ref={(el) => (sections.current[1] = el)}  className={`panel ${activePanel === 1 ? "active-panel" : ""}`} >
          <PartnerSupport 
          title={ <h2 className='lg:text-4xl md:text-2xl text-xl font-medium text-center'>Your Trusted Partner for Seamless Fleet <br/>Management and <span className='shadow-red text-dark-red'> Support </span></h2> } 
          numberOfItems={3} 
          arrayData={cardData}
          gridClass="lg:grid-cols-3 md:grid-cols-auto" />
          {activePanel === 1 ? (
            <div className="icon-overlay">
              <BsCircleFill className="icon text-dark-red" />
            </div>
          ) : (
            <div className="icon-overlay">
              <BsCircleFill className="icon text-gray-600" />
            </div>
          )}
      </div>
    
   {isMobile && 
    <>
      <AboutUs 
        title={<h2 className='lg:text-4xl md:text-2xl text-xl font-medium'>Leverage Piston's cardless solution to drive unparalleled savings for you</h2>}
        />
      <PartnerSupport 
          title={ <h2 className='lg:text-4xl md:text-2xl text-xl font-medium text-center'>Your Trusted Partner for Seamless Fleet Management and <span className='shadow-red text-dark-red'> Support </span></h2> } 
          numberOfItems={3} 
          arrayData={cardData}
          gridClass="lg:grid-cols-3 md:grid-cols-auto"
          paddingTop="lg:pt-16 pt-8" />
    </>
   }
    {/* <div className={`h-10 w-10 ${activePanel ? "bg-green-500" : "bg-gray-500" } rounded-full absolute top-1/2 translate-y-1/2 z-50 right-5`}>{item}</div> */}
      
    
    </>
  )
}

export default GsapContainer