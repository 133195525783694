import React from 'react';
import BannerBg from '../../assets/images/gas-station-banner-1.png'
import { useState } from 'react';
import { useEffect } from 'react';

const InnerBanner = ({title, desc, subTitle, editableText, index,indexDesc}) => {
    const [isMobile, setIsMobile] = useState(false);

    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 991); // Adjust the width as needed
    };
    useEffect(() => {
      checkMobile(); // Check on component mount
  
      // Add event listener for window resize
      window.addEventListener('resize', checkMobile);
  
      // Cleanup listener on component unmount
      return () => window.removeEventListener('resize', checkMobile);
    }, []);

    // const bannerStyle = {
    //     backgroundImage: `url(${BannerBg})`,
    //     height: `${isMobile ? "350px" : "700px"}`,
    //     marginTop: "0px",
    //     backgroundSize: `${isMobile ? "cover" : "contain"}`,
    //     backgroundRepeat: "no-repeat",
    //     backgroundPosition: "30vw center",
    //     width: "100%"
    // };

const titleArr = title.split(" ");

const descArr = desc?.split(" ");
// console.log("line 17",titleArr);

    // console.log("line 15",index);

    // {title.split(" ").map((item) => title[index]!== item && <span className='text-dark-red shadow-light relative'>{title[index]}</span>)}

  return (
    <>
        <section className={`relative banner-bg ${isMobile ? "min-h-[320px]" : "min-h-[650px]"} overflow-hidden flex items-center z-10 w-full`}>
            <div className='lg:max-w-screen-xl md:max-w-screen-md max-w-screen-sm px-5 xl:px-0 w-full flex flex-wrap items-center justify-between mx-auto h-full lg:pb-20 pb-0 lg:pt-10 pt-16 relative'>
                <div className="md:w-auto w-full relative z-10 lg:text-left text-center">
                    <h1 className='lg:text-5xl text-3xl font-bold text-white'>  
                      {titleArr.map((word, idx) => (
                          <React.Fragment key={idx}>
                          {idx === index ? (
                            <span className="text-dark-red shadow-light relative">
                              {word}
                            </span>
                          ) : (
                            word
                          )}
                          {idx === index && <br />} {/* Insert <br /> after the highlighted word */}
                          {idx < titleArr.length - 1 && ' '}
                        </React.Fragment>
                      ))}
                    </h1> 
                    <br/>
                    {/* <span className='text-dark-red shadow-light relative'>{titleArr[index]? titleArr[index] : null}</span> */}
                    <p className='text-white text-opacity-50 lg:text-xl text-base'>        
                      {descArr.map((word, idx) => (
                        <React.Fragment key={idx}>
                          {word}
                          {idx === indexDesc && <br />}
                          {idx < descArr.length - 1 && " "}
                        </React.Fragment>
                      ))}
                    </p>                   
                </div>
                <div className={`md:w-2/3 w-full absolute -z-10 right-0 ${isMobile ? "hidden" : "flex"}`}>
                    <img src={BannerBg} alt="" className='w-full' />
                </div>
            </div>

        </section> 
    </>
  )
}

export default InnerBanner

