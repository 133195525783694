import React from 'react'

const AboutUs = ({title}) => {


  return (
    <>
        <section className='relative overflow-hidden flex items-center z-10 w-full about-sec lg:py-48 py-16 border-b border-gray-500 border-opacity-30 bg-black lg:h-screen h-auto'>
            <div className='lg:max-w-screen-lg md:max-w-screen-md max-w-screen-sm px-5 xl:px-0 w-full flex flex-wrap items-center justify-center mx-auto lg:gap-0 gap-10'>
                <div className="w-full text-center">
                    <p className='text-dark-red text-base lg:mb-4 mb-1 font-medium'>A 100% Cardless Solution</p>
                    {title}
                </div>
            </div>
        </section>
    </>
  )
}

export default AboutUs