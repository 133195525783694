import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomepageView from './components/pages/Homepage/HomepageView';
import Merchant from './components/pages/Merchant';
import './globals.css';
import Page from './components/pages/Page';
// import './assets/js/jQueryv3.2.1.min';

function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route exact index element={<HomepageView  />} />
          <Route exact path="/merchant" element={<Merchant  />} />
          <Route path="/:slug" element={<Page  />} />
        </Routes>
      </BrowserRouter>
  );
}

export default App;
