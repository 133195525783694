import React from 'react';
// import BannerBg from '../../../../assets/images/banner-bg.png'
import config from '../../../../config/config';

const Banner = ({home}) => {

    const bannerStyle = {
        backgroundImage: `url(${config.assetURL + home?.bannerImage})`,
        height:"700px",
        marginTop: "0px",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: 'top center'
    };

  return (
        <section className='relative xl:max-h-[700px] max-h-[500px] banner-bg overflow-hidden flex items-center z-10 w-full' style={bannerStyle}>
            <div className='lg:max-w-screen-xl md:max-w-screen-md max-w-screen-sm px-5 xl:px-0 w-full flex flex-wrap items-center justify-between mx-auto'>
                <div className="w-auto">
                    <h1 className='xl:text-7xl md:text-5xl text-2xl font-bold text-white pe-40' dangerouslySetInnerHTML={{ __html: home?.bannerTitle }}>
                        {/* Fuel Faster, 
                        <br/> Safer and Smarter
                        <br/> with <span className='text-dark-red'>Piston Pay.</span> */}
                    </h1>
                    <p className='xl:text-2xl text-base font-bold mt-4'>{ home?.bannerDescription }</p>
                    
                </div>
            </div>
        </section>
  )
}

export default Banner