import React, { useState } from 'react'
import BannerBg from '../../assets/images/coming-bg.png'
import { useEffect } from 'react';

const ComingSoon = () => {
    const [isMobile, setIsMobile] = useState(false);

    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 991); // Adjust the width as needed
    };
    useEffect(() => {
      checkMobile(); // Check on component mount
  
      // Add event listener for window resize
      window.addEventListener('resize', checkMobile);
  
      // Cleanup listener on component unmount
      return () => window.removeEventListener('resize', checkMobile);
    }, []);

    const bannerStyle = {
        backgroundImage: `url(${BannerBg})`,
        height: `${isMobile ? "300px" : "600px"}`,
        marginTop: "0px",
        backgroundSize: `${isMobile ? "cover" : "contain"}`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top center",
        width: "100%"
    };
  return (
    <>
        <div className="comingsoon-sec relative w-full pb-16 bg-black min-h-[600px] flex items-center" style={bannerStyle}>
            <section className='relative w-full mt-24'>
                <div className='px-5 xl:px-0 w-full flex flex-wrap items-center justify-center mx-auto background-blur-custom border border-gray-600 border-opacity-30 rounded-lg'>
                    <div className="flex justify-center flex-col w-full gap-5 py-32">
                        <h2 className='md:text-6xl text-3xl font-medium text-center'>Coming Soon</h2>
                    </div>
                </div>
            </section>
            
        </div>
    </>
  )
}

export default ComingSoon