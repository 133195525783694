import { GET, POST } from "../config/ApiConfig";
import config from "../config/config";

export const getHome = async () => {
  return await GET('home');
};

export const getMerchant = async () => {
  return await GET('merchant');
};

export const sendMessage = async (data) => {
  return await POST('contact', data);
};

export const getPage = async (slug) => {
  return await GET('page/' + slug);
};