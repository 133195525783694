import React, { useEffect, useRef, useState } from 'react'
import Logo from '../../assets/images/logo.png'
import SmallLogo from '../../assets/images/small_logo.png'
import { BsList, BsFilterLeft } from 'react-icons/bs';
import { Link, useLocation } from 'react-router-dom';
// import 'flowbite';

const Header = () => {

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isFixed, setIsFixed] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const menuRef = useRef(null);
    const btnRef = useRef(null);
    const dropdownRef = useRef(null);

    const location = useLocation();
    const isActive = (path) => location.pathname === path;

    const toggleMenu = () => {
        setIsMenuOpen((prev) => !prev);
    }
    const closeMenu = () => {
        setIsMenuOpen(false);
    }
    const handleClickOutside = (event) => {
        if (btnRef.current && btnRef.current.contains(event.target)){
            return 
        }
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setIsMenuOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);

      useEffect(() => {
        const handleScroll = () => {
          if (window.scrollY > 100) { // Adjust the scroll position value as needed
            setIsFixed(true);
          } else {
            setIsFixed(false);
          }
        };
    
        window.addEventListener('scroll', handleScroll);
        
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

      const checkMobile = () => {
        setIsMobile(window.innerWidth <= 991); // Adjust the width as needed
      };
      useEffect(() => {
        checkMobile(); // Check on component mount
    
        // Add event listener for window resize
        window.addEventListener('resize', checkMobile);
    
        // Cleanup listener on component unmount
        return () => window.removeEventListener('resize', checkMobile);
      }, []);

  return (
    <>
        <nav className={`bg-transparent z-20 w-full px-5 xl:px-0 ${isFixed ? 'fixed' : ''}`}>
            <div className="lg:max-w-screen-xl md:max-w-screen-md max-w-sm px-3 flex flex-wrap items-center justify-between mx-auto navbar lg:rounded-2xl rounded-xl border border-gray-600 background-blur-custom">
                
                <Link to='/' className="flex items-center space-x-3">
                    <img src={`${isMobile ? SmallLogo : Logo}`} className="" alt="Piston Logo" />
                </Link>
                <button 
                    ref={btnRef}
                    onClick={toggleMenu}
                    data-collapse-toggle="navbar-dropdown" 
                    type="button" 
                    className="inline-flex items-center p-1 w-8 h-8 justify-center text-xl text-gray-500 rounded-lg lg:hidden hover:bg-transparent focus:outline-none focus:ring-none dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" 
                    aria-controls="navbar-dropdown" 
                    aria-expanded={isMenuOpen ? 'true' : 'false'}>
                    <span className="sr-only">Open main menu</span>
                    <BsList style={{height:"30px", width:"30px"}} className={`${isMenuOpen ? "text-white" : "text-gray-600"}`} />
                </button>
                
                
                <div  
                    ref={menuRef} 
                    className={`w-full lg:block lg:w-auto ${isMenuOpen ? 'block' : 'hidden'}`} id="navbar-dropdown"
                    >
                    <ul className="flex lg:flex-row flex-col lg:items-center items-start gap-5 text-base py-1.5 lg:ps-3 ps-0 mt-4 md:mt-0 font-normal">
                        <li>
                            <Link to='/' onClick={closeMenu} className={`block py-0 lg:px-3 px-0 ${isActive('/') ? 'text-dark-red font-medium' : 'text-white text-opacity-50 hover:text-dark-red'} transition-all md:bg-transparent`}>Home</Link>
                        </li>
                        <li>
                            <Link to='/gas-station' onClick={closeMenu} className={`block py-0 lg:px-3 px-0 ${isActive('/gas-station') ? 'text-dark-red font-medium' : 'text-white text-opacity-50 hover:text-dark-red'} transition-all md:bg-transparent`}>Gas Station</Link>
                        </li>
                        
                        {/* <li>
                            <Link to='/' onClick={closeMenu} className={`block py-0 px-3 font-bold ${isActive('/about-us') ? 'text-dark-red' : 'text-white text-opacity-50 hover:text-dark-red'} transition-all md:bg-transparent`}>About Us</Link>
                        </li> */}
                        <li>
                            <Link to='/contact-us' onClick={closeMenu} className={`block py-0 lg:px-3 px-0 ${isActive('/contact-us') ? 'text-dark-red font-medium' : 'text-white text-opacity-50 hover:text-dark-red'} transition-all md:bg-transparent`}>Contact Us</Link>
                        </li>
                        
                    </ul>
                {isMobile && 
                    <ul className={`flex lg:flex-row flex-col lg:items-center items-start gap-5 text-base py-1.5 lg:ps-3 ps-0 mt-4 md:mt-0 lg:border-0 border-t pt-4 border-gray-600`}>
                        <li>
                            <Link to='https://fleet.pistonpay.com/' target='_blank' className="block py-0 lg:px-3 px-0 text-white text-md font-bold hover:text-dark-red transition-all md:bg-transparent">Login</Link>
                        </li>
                        <li>
                            <Link className='text-white bg-dark-red hover:bg-[#E94A4F] rounded-lg lg:text-md text-sm font-bold px-8 lg:py-3 py-2 text-center flex items-center'>
                                Get Started 
                            </Link>
                        </li>
                    </ul>
                }
                </div>
                {!isMobile && 
                <div className='w-full lg:w-auto lg:flex'>
                    <ul className='flex lg:flex-row flex-col items-center gap-5 text-base py-1.5 ps-3 mt-4 md:mt-0'>
                        <li>
                            <Link to='https://fleet.pistonpay.com/' target='_blank' className="block py-0 px-3 text-white text-md font-bold hover:text-dark-red transition-all md:bg-transparent">Login</Link>
                        </li>
                        <li>
                            <a href="#contact-form" className='text-white bg-dark-red hover:bg-[#9a1c1f] rounded-lg text-md font-bold px-8 py-3 text-center flex items-center'>
                                Get Started 
                            </a>
                        </li>
                    </ul>
                </div>
                }
            </div>
        </nav>
    </>
  )
}

export default Header