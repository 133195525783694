import React from 'react'
import Header from './Header'
import Footer from './Footer'

const Layout = ({children, settings}) => {
  return (
    <>
        <Header />
          <main className=''>
            {children}
          </main>
        <Footer settings={settings} />
    </>
  )
}

export default Layout